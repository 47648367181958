/**
 * Sidebar overlay, mainly used on filter page
 *
 * @author Martijn Rijnja <martijn.rijnja@pon.com>
 */

define('js/sidebar-overlay',[
    'jquery',
    'uiComponent',
    'blackOverlay',
    'bodyScrollLock'
], function ($, Component, blackOverlay, bodyScrollLock) {
    "use strict";

    return Component.extend({
        defaults: {
            sidebar: '.s--filterSidebar',
            toggleButton: '.toggle-filter',
            openClass: 'open',
            backgroundOverlay: '.black-overlay'
        },

        /**
         * Constructor
         */
        initialize: function () {
            this._super();
            var self = this;

            blackOverlay.initialize();

            $(self.toggleButton).click(function (e) {
                e.preventDefault();
                self.toggleSidebar();
            });

            $(document).on('closeMenus', function() {
                $(self.sidebar).removeClass(self.openClass);
                $(document).trigger('closeFilter');
                bodyScrollLock.clearAllBodyScrollLocks();
            });
        },

        toggleSidebar: function (mode) {
            var sidebar = $(this.sidebar);

            switch (mode) {
                case 'close':
                    sidebar.removeClass(this.openClass);
                    blackOverlay.toggleOverlay('close');
                    bodyScrollLock.disableBodyScroll(sidebar);
                    break;
                default:
                    if (sidebar.hasClass(this.openClass)) {
                        bodyScrollLock.enableBodyScroll(sidebar[0]);
                    } else {
                        bodyScrollLock.disableBodyScroll(sidebar[0]);
                    }
                    sidebar.toggleClass(this.openClass);
                    blackOverlay.toggleOverlay();
            }
        }
    });
});

