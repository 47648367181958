
(function(template){
define("vue!KalkhoffESSearchComponents/SearchInput.vue", ['Vue'], function (Vue) {
    Vue.component('search-input', {
        template: template,

        props: ['value', 'placeholder', 'triggerLimit', 'redirectUrl'],

        methods: {
            clearAndClose() {
                this.$emit('clear');
                this.$refs.input.value = '';

                document.querySelector('.c-topnav__main-item.search .c-topnav__submenu').classList.remove('is-open');
                document.querySelector('.c-topnav__mobile-search').click();
            },

            search(query) {
                if (query.length < this.$props.triggerLimit) {
                    Vue.nextTick(() => this.$emit('clear', query));
                    return;
                }

                if (this.timeout) {
                    clearTimeout(this.timeout);
                }

                const emitAction = !!query ? 'search' : 'clear';
                this.timeout = setTimeout(() => this.$emit(emitAction, query), 200);
            },

            redirectToSearchResults(query) {
                if (this.$props.redirectUrl && query.length >= this.$props.triggerLimit) {
                    let redirectUrl = this.$props.redirectUrl;
                    if (redirectUrl.endsWith('/')) {
                        redirectUrl = redirectUrl.slice(0, -1);
                    }
                    window.location = `${redirectUrl}?q=${query}#page=1`;
                }
            }
        }
    });
});
})('' + 
' <div>' + 
' <input class="essearch__field" type="text" @input="search($event.target.value)" v-on:keyup.enter="redirectToSearchResults($event.target.value)" :placeholder="placeholder" ref="input">' + 
' <div :class="{\'essearch__clear--show\': value.length > 0 }" class="essearch__clear" @click="clearAndClose()"></div>' + 
' </div>' + 
'' + '');
