define('js/theme',['jquery'], function ($) {
    // PDP Gallery
    $('.gallery-placeholder').on('gallery:loaded', function () {
        $('body').addClass('page-loaded');

        var fotorama = $(this).data('gallery');

        // Remove default arrows
        if ($('.fotorama__thumb__arr').length > 0) {
            $('.fotorama__thumb__arr').remove();
        }

        // We don't need arrows
        if (fotorama.fotorama.size < 4) {
            return;
        }

        // Up arrows one level to be able to style them
        $('<div class="fotorama__thumb_arrows">' +
            '<div class="fotorama__thumb__arr fotorama__thumb__arr--left fotorama__thumb__arr--prev"></div>' +
            '<div class="fotorama__thumb__arr fotorama__thumb__arr--right fotorama__thumb__arr--next"></div>' +
            '</div>')
        .insertAfter('.fotorama__nav--thumbs');

        $('.fotorama__thumb__arr--prev').click(function () {
            fotorama.prev();
        });
        $('.fotorama__thumb__arr--next').click(function () {
            fotorama.next();
        });
    });

    $('.gallery-placeholder').on('fotorama:load fotorama:show', function (e, fotorama) {
        var firstSlide = (fotorama.activeIndex === 0);
        var lastSlide = (fotorama.activeIndex === (fotorama.size - 1));

        $('.fotorama__thumb__arr--prev').toggleClass('fotorama__thumb__arr--disabled', firstSlide);
        $('.fotorama__thumb__arr--next').toggleClass('fotorama__thumb__arr--disabled', lastSlide);
    });
});

