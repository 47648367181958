/**
 * Small util method that checks if a element is inside the current viewport
 *
 * @author Jeroen Reumkens <jeroen.reumkens@tamtam.nl>
 */

define('js/utils/element-in-viewport',[
    'jquery'
], function($) {
    'use strict';

    var $win = $(window);

    function isElementInViewport(el, completeHeightInView, offset) {
        var viewport = {top : $win.scrollTop()},
            bounds = el.offset(),
            offset = offset || 0;

        viewport.bottom = viewport.top + $win.height();
        bounds.bottom = bounds.top + el.outerHeight();

        if ( completeHeightInView ) {
            return (viewport.bottom > ( bounds.top - offset ) || viewport.top < ( bounds.bottom + offset ));
        } else {
            return (viewport.bottom > ( bounds.top - offset ) );
        }
    }


    return isElementInViewport;

});

