define('Ponbike_LazyLoad/js/lazyloader',[
    'jquery',
    'jquery-ui-modules/widget',
    'Ponbike_LazyLoad/js/vendor/lazyload.amd',
    'Ponbike_LazyLoad/js/vendor/interaction-observer-amd'
], function ($, ui, LazyLoad, InteractionObserver) {
    'use strict';

    $.widget('ponbike.lazyloader', {
        el: {
            selector: ".lazy-img",
            preloadObject: "preload-object",
            threshold: '500'
        },

        /**
         * Initialize LazyLoad
         * @private
         */
        _create: function () {
            var self = this;
            window.lazyLoadInstance = new LazyLoad({
                elements_selector: this.el.selector,
                threshold: this.el.threshold,
                callback_loaded: function (el) {
                    if ($(el).prev().hasClass(self.el.preloadObject)) {
                        $(el).prev().css('position', 'absolute');
                        $(el).prev().css('opacity', 0);
                        $(el).prev().css('height', 0);
                    }
                }
            });
        },

        /**
         * Bind global update triggers for AJAX loaded lazyload content
         * @private
         */
        _init: function () {
            if (window.lazyLoadInstance) {
                $('body').on('processStop', function () {
                    window.lazyLoadInstance.update();
                });
            }
        }

    });

    return $.ponbike.lazyloader;
});

