define('Kalkhoff_Modal/js/modal',['jquery'], function ($) {
  $(document).ready(function () {
    const modalTarget = document.getElementById('ponbikeModal');

    if (!modalTarget) {
      return;
    }

    $(document).on('click', '[data-modal-trigger]', (e) => toggleModal(modalTarget, e.target));
    $(document).on('click', '[data-modal-close]', (e) => toggleModal(modalTarget));
    $(document).on('click', '#ponbikeModal', (e) => {
      if (e.target !== modalTarget) {
        return;
      }

      toggleModal(e.target);
    });

    /**
     * Toggle modal visibility
     *
     * @param modalTarget
     * @param trigger
     */
    toggleModal = (modalTarget, trigger = null) => {
      const targetTitle = modalTarget.querySelector('[data-modal-target-title]'),
        targetContent = modalTarget.querySelector('[data-modal-target-content]');

      targetTitle.innerHTML = trigger ? decodeURIComponent(trigger.dataset.title) : '';
      targetContent.innerHTML = trigger ? decodeURIComponent(trigger.dataset.content) : '';

      modalTarget.classList.toggle('c-modal--visible');
      document.body.classList.toggle('modal-open');
    }
  });
});

