define('Ponbike_Compare/js/addItem',[
    'jquery'
], function ($) {
    'use strict';

    $.widget('mage.addItem', {
        elements: {
            compareBar: '.compare-bar',
            compareContainer: '.compare-bar__container',
            deleteItem: '.action.delete',
            foldableWrapper: 'foldable__wrapper'
        },

        _create: function () {
            this._bindRemoveFromCompare();
            this._fetchCurrentCompareItems();
            this._initiateWindowBindings();
        },

        _initiateWindowBindings: function() {
            const self = this;

            if (!window.compareItems) {
                window.compareItems = [];
            }

            if (!window.compareInitiated) {
                window.compareInitiated = false;
            }

            window.addToCompare = function (productId, store, remove = false) {
                self._toggleCompareItem(productId, store, remove);
            }
        },

        _fetchCurrentCompareItems: function() {
            const self = this;

            $.ajax({
                url: '/catalog/product_compare/ajax',
                success: (returnValue) => {
                    window.compareItems = returnValue.compareItems;

                    if (window.compareInitiated !== true) {
                        self._updateCompareBar(returnValue);
                    }
                },
            });
        },

        _bindRemoveFromCompare: function () {
            const self = this;

            $(self.element).on('click', self.elements.deleteItem, function () {
                self._toggleCompareItem($(this).data('id'), $(this).data('store'), true);
            });
        },

        _toggleCompareItem: function (productId, store, remove = false) {
            const self = this;

            $(self.elements.compareBar).addClass('loading');

            $.ajax({
                url: '/' + store + '/catalog/product_compare/ajax',
                data: {
                    'product': productId
                },
                success: function (returnValue) {
                    self._updateCompareItems(remove, productId);
                    self._updateCompareBar(returnValue);

                    const event = new CustomEvent('compareBarUpdated', {detail: {remove: remove, productId: productId}});

                    window.dispatchEvent(event);
                },
                complete: function (returnValue) {
                    Object.assign({ bannerHTML: '', noItems: true, compareItems: [] }, returnValue);
                }
            });
        },

        _updateCompareItems: function(remove, productId) {
            if (remove) {
                window.compareItems = window.compareItems.filter((compareId) => compareId != productId);
            } else {
                window.compareItems.push(productId);
            }
        },

        _updateCompareBar: function (cb) {
            var self = this,
                bannerHTML = cb.bannerHtml;

            if (!cb.hasOwnProperty('replace')) {
                if (cb.noItems === false) {
                    bannerHTML = bannerHTML.replace(self.elements.foldableWrapper, `${self.elements.foldableWrapper} open`);
                }

                $(self.elements.compareBar).html(bannerHTML);
            }

            self._reload();
            self._finalize(cb);
        },

        _reload: function () {
            const self = this;

            $(self.elements.compareBar).removeClass('loading');
            $(self.elements.compareBar).trigger('contentUpdated');
        },

        _finalize: function (cb) {
            const self = this;

            window.compareInitiated = true;

            if (!cb.hasOwnProperty('replace')) {
                if (cb.noItems === true) {
                    $(self.elements.compareBar).removeClass('active');
                    $(self.elements.compareContainer).removeClass('has-items').removeClass('open');
                } else {
                    $(self.elements.compareContainer).addClass('has-items');
                }
            }
        },
    });

    return $.mage.addItem;
});

