define('Kalkhoff_BlueFootEnhancements/js/stories-slider',['jquery', 'slick'], function ($, slick) {
    'use strict';

    const sliderSelector = '.s--stories__slider';
    const defaultSliderConfig = {
        fade: true,
        swipe: false,
        arrows: false,
        draggable: false,
        touchMove: false
    };

    let mainSliderConfig = Object.assign({}, defaultSliderConfig, {
        dots: true,
        fade: false,
        swipe: true,
        arrows: true,
        draggable: true,
        touchMove: true,
        adaptiveHeight: true,
        customPaging: () => {},
        dotsClass: 's--stories__dots',
        appendDots: '.s--stories__navigation',
        appendArrows: '.s--stories__navigation',
        prevArrow: '<div class="s--stories__navigation-arrow s--stories__navigation-arrow--prev"></div>',
        nextArrow: '<div class="s--stories__navigation-arrow s--stories__navigation-arrow--next"></div>'
    });

    let storiesSliderComponent = (config, element) => {
        let sliderConfig = defaultSliderConfig;

        if (config.isMainSlider === true) {
            let mainSliderSelector = '';
            element.classList.forEach(className => mainSliderSelector += `.${className}`);

            sliderConfig = mainSliderConfig;
            sliderConfig.asNavFor = `${sliderSelector}:not(${mainSliderSelector})`;
        }

        $(element).slick(sliderConfig);
    }

    return storiesSliderComponent;
});

