/*jshint browser:true jquery:true*/
/*global confirm:true*/
define(
    'Pon_CookieManager/js/components/storage-model',[
        'jquery',
        'mage/cookies'
    ],
    function ($) {
        'use strict';
        return {
            /**
             * Set a cookie's value by cookie name based on optional cookie options.
             * @param {String} name - The name of the cookie.
             * @param {String} value - The cookie's value.
             * @param {Object} options - Optional options (e.g. lifetime, expires, path, etc.)
             */
            set: function (name, value, options) {
                if (options.raw) {
                    this.setRaw(name, value, options);
                } else {
                    $.mage.cookies.set(name, value, options);
                }

                if (_.isEmpty(document.cookie) && this.localStorageAvailable()) {
                    localStorage.setItem(name, value);
                }
            },

            /**
             * Get a cookie's value by cookie name.
             * @param {String} name  - The name of the cookie.
             * @return {(null|String)}
             */
            get: function (name) {
                var cookieValue = $.mage.cookies.get(name);

                if (!cookieValue && this.localStorageAvailable()) {
                    cookieValue = localStorage.getItem(name);
                }

                return cookieValue;
            },

            /**
             * Check if local storage is available in the browser
             * @returns {boolean}
             */
            localStorageAvailable: function () {
                return window["localStorage"] !== null;
            },

            /**
             * Set a cookie's value (without encodeURIComponent) by cookie name based on optional cookie options.
             * @param {String} name - The name of the cookie.
             * @param {String} value - The cookie's value.
             * @param {Object} options - Optional options (e.g. lifetime, expires, path, etc.)
             */
            setRaw: function (name, value, options) {
                var expires,
                    path,
                    domain,
                    secure;

                options = $.extend({}, $.mage.cookies.defaults, options || {});
                expires = this.lifetimeToExpires(options, $.mage.cookies.defaults) || options.expires;
                path = options.path;
                domain = options.domain;
                secure = options.secure;

                document.cookie = name + '=' + value +
                    (expires ? '; expires=' + expires.toUTCString() :  '') +
                    (path ? '; path=' + path : '') +
                    (domain ? '; domain=' + domain : '') +
                    (secure ? '; secure' : '');
            },

            /**
             * Calculate cookie expiration date based on its lifetime.
             * @param {Object} options - Cookie option values
             * @return {Date|null} Calculated cookie expiration date or null if no lifetime provided.
             * @private
             */
            lifetimeToExpires: function(options, defaults) {
                var expires,
                    lifetime;

                lifetime = options.lifetime || defaults.lifetime;

                if (lifetime && lifetime > 0) {
                    expires = options.expires || new Date();

                    return new Date(expires.getTime() + lifetime * 1000);
                }

                return null;
            }
        };
    }
);

