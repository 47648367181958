define('Kalkhoff_BlueFootEnhancements/js/suggestions-slider',['jquery', 'slick'], function ($, slick) {
    'use strict';

    const suggestionsComponent = (config, element) => {
        setTimeout(() => {
            $(element).slick({
                dots: false,
                arrows: true,
                infinite: false,
                centerMode: false,
                mobileFirst: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                appendArrows: $(element).parent().find('.s--suggestions__arrows'),
                prevArrow: '<div class="s--suggestions__arrow s--suggestions__arrow--prev"></div>',
                nextArrow: '<div class="s--suggestions__arrow s--suggestions__arrow--next"></div>',
                responsive: [
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 2,
                        }
                    },
                    {
                        breakpoint: 1280,
                        settings: {
                            slidesToShow: 3,
                        }
                    },
                    {
                        breakpoint: 1920,
                        settings: {
                            slidesToShow: 4,
                        }
                    }
                ]
            });
        }, 1000);
    }

    return suggestionsComponent;
});

