
(function(template){
    define("vue!KalkhoffESSearchComponents/Result.vue", ['Vue'], function (Vue) {
        Vue.component('search-result', {
            template: template,

            props: ['type', 'data']
        });
    });
})('' + 
' <div class="essearch__resultsItem" :class="`essearch__resultsItem--${type}`">' + 
' <a :href="data.url" class="essearch__resultsItem-container" :class="[{\'essearch__resultsItem-container--noImage\': data.image===undefined }, `essearch__resultsItem-container--${type}`]">' + 
' <img v-if="data.image" class="essearch__resultsItem-image-container" :class="`essearch__resultsItem-image-container--${type}`" :src="data.image" alt="" loading="lazy" />' + 
'' + 
' <div class="essearch__resultsItem-meta" :class="`essearch__resultsItem-meta--${type}`">' + 
' <span v-if="data.title" class="essearch__resultsItem-title" :class="`essearch__resultsItem-title--${type}`">{{ data.title }}</span>' + 
' <div v-if="data.price" class="essearch__resultsItem-price" :class="`essearch__resultsItem-price--${type}`">{{ data.price }}</div>' + 
' </div>' + 
' </a>' + 
' </div>' + 
'' + '');
