/*jshint browser:true jquery:true*/
/*global confirm:true*/
define(
  'Pon_AJAXCatalog/js/navigation',[
    'jquery',
    'jquery-ui-modules/slider',
    'underscore',
    'uiComponent',
    'mage/template',
    'Magento_Catalog/js/price-utils',
    'Magento_Ui/js/lib/knockout/bootstrap',
    'jquery/bb-bbq',
    'jquery/is-in-viewport'
  ],
  function ($, ui, _, Component, mageTemplate) {
    "use strict";

    var self,
        $document = $(document),
        $window = $(window);

    /**
     * Transforms HTML entities
     * @returns string
     */
    String.prototype.cleanHtml = function () {
      return new DOMParser().parseFromString(this, 'text/html').documentElement.textContent;
    };

    return Component.extend({
      defaults: {
        filterTmplSelector: '#pon-ajaxcatalog-filter-',
        navigationArea: '#filters',
        navigationItemSelector: '.filter-options-content .item',
        navigationItemClearSelector: '.bike-filter__form-clear',
        productsArea: '.category-products .product-items',
        productItemClass: 'product-item',
        productTmplSelector: '#pon-ajaxcatalog-list-',
        priceSliderSelector: '.price-slider',
        toolbarSelector: '.toolbar-amount',
        toolbarTmplSelector: '#pon-ajaxcatalog-toolbar-',
        isInViewportTolerance: 300,
        compiledTemplates: {},
        ajaxProxyUrl: '',
        isInitialized: false
      },

      /**
       * Constructor
       */
      initialize: function () {
        this._super();
        self = this;

        self.initProperties();
        self.initEventHandlers();

        self.setPage(self.endPage);
        self.doRequest(false);
      },

      /**
       * @private
       */
      initProperties: function () {
        self.$filterList = $(self.navigationArea);
        self.$productList = $(self.productsArea);
        self.$toolbar = $(self.toolbarSelector);
        self.insertDirection = 'append';
        self.isEnd = false;
        self.hasScrolled = false;
        self.endPage = self.getPage() || 1;
        self.params = $.deparam.querystring();
        self.url = document.location.href;
      },

      /**
       * Init event handlers
       */
      initEventHandlers: function () {
        $window.on('scroll.ajaxlistHasScrolled', _.debounce(function () {
          if (!self.hasScrolled && $window.scrollTop() > self.isInViewportTolerance) {
            self.hasScrolled = true;
          }
        }, 100));

        $document.on('click', self.navigationItemClearSelector, function () {
          $document.trigger('ajaxlist:reset');
        });

        $document.on('ajaxlist:filter', function (event, data) {
          if (_.has(data, 'attribute') && _.has(data, 'value')) {
            $.bbq.pushState(data.attribute, data.value);
          }

          self.resetPage();
          self.doRequest(false);
        });

        $document.on('ajaxlist:reset', function (event) {
          $.bbq.removeState();

          self.resetPage();
          self.doRequest(false);
        });

        $document.on('ajaxlist:clear', function (event, data) {
          $.bbq.removeState(data.attribute);

          self.resetPage();
          self.doRequest(false);
        });

        $(document).on('categoriesPreloaded', function (event, data) {
          self.rebuild(data);
          self.afterRequest();
        })
      },

      /**
       * @param {boolean} lazy
       * @returns {undefined}
       */
      doRequest: function (lazy, params) {
        self.isLazyLoad = !_.isUndefined(lazy) && lazy === true;

        if (window.$preloadCategoriesEnabled === true
            && !self.isInitialized
            && typeof window.$preloadCategories !== 'undefined'
        ) {
          window.$preloadCategories();
          return;
        }

        params = params || {};

        var data = _.extend(self.getUrlParams(), {
          ajax: true,
          page: self.page,
          isLazy: self.isLazyLoad
        }, params);

        $.ajax({
          url: self.url,
          method: 'GET',
          data: data,
          cache: false,
          showLoader: true,
          success: function (response) {
            self.rebuild(response);
          }
        }).done(function () {
          self.afterRequest();
        });
      },

      /**
       * afterRequest
       */
      afterRequest: function () {
        self.updateUrl();
        self.dispatchEvent('ajaxlist:queried');
        self.isInitialized = true;
      },

      /**
       * @param data
       */
      rebuild: function (data) {
        self.isEnd = data.isEnd;

        if (!self.isLazyLoad) {
          self.dispatchEvent('ajaxlist:responded', data);

          self.$toolbar.empty();
          self.$filterList.empty();
          self.$productList.empty();

          self.attributes = data.attributes;
          self.initLayeredNavigation(data.navigation);
          self.initToolbar(data.toolbar);
        }

        self.initProductList(data.products);

        if (!self.isEnd) {
          self.initLazyLoader();
        }

        if (!self.isLazyLoad) {
          self.dispatchEvent('ajaxlist:initialized');
        }
      },

      /**
       * @param data
       */
      initToolbar: function (data) {
        if (self.$toolbar.length) {
          self.setInnerHtml(self.$toolbar[0], self.getToolbarTemplate({data: data}));
        }
      },

      /**
       * Init lazy load
       */
      initLazyLoader: function () {
        $window.on('scroll.ajaxlist', _.throttle(self.lazyLoadNextPage, 500));

        self.lazyLoadNextPage();
      },

      /**
       * Render and init product list
       *
       * @param {type} products
       * @returns {undefined}
       */
      initProductList: function (products) {
        var fragment = document.createDocumentFragment(),
            isFirst = true;

        _.each(products, function (product, index) {
          self.dispatchEvent('ajaxlist:initProduct:before', {product: product});

          var html = self.getProductTemplate({data: product}),
              el = document.createElement('li');

          el.className = self.productItemClass;
          if (_.has(product, 'product_id')) {
            el.setAttribute('data-product-id', product.product_id);
          }

          if (isFirst) {
            el.setAttribute('data-page', self.getPage());
          }

          self.setInnerHtml(el, html);
          fragment.appendChild(el);
          isFirst = false;

          self.dispatchEvent('ajaxlist:initProduct:after', {product: product, productHtml: html});
        });

        if (self.$productList.length) {
          if (self.insertDirection === 'prepend') {
            self.$productList[0].insertBefore(fragment, self.$productList[0].firstChild);
          } else {
            self.$productList[0].appendChild(fragment);
          }
        }

        self.dispatchEvent('ajaxlist:initList:after', {products: products});
      },

      /**
       * @param {type} layer
       * @returns {undefined}
       */
      initLayeredNavigation: function (layer) {
        self.dispatchEvent('ajaxlist:initLayer:before', {layer: layer});


        if (self.$filterList.length) {
          self.setInnerHtml(self.$filterList[0], self.getLayerNavigationHtml(layer));
        }

        self.dispatchEvent('ajaxlist:initLayer:after', {filters: self.$filterList});

        self.initFilters();
      },

      /**
       * @param layer
       * @returns {string}
       */
      getLayerNavigationHtml: function (layer) {
        var html = '';

        _.each(layer, function (attribute) {
          html += self.getFilterTemplate({
            data: _.extend(attribute, {
              template: attribute.template ? attribute.template : attribute.attribute,
              layer: layer
            })
          });
        });

        html += self.getFilterTemplate({data: {template: 'clear'}});
        return html;
      },

      /**
       * Render and init layered navigation filters
       */
      initFilters: function () {
        $(self.navigationItemSelector).on('click change', self.handleFilterItem.bind(this));

        $(self.priceSliderSelector).each(function (idx, element) {
          $(element).slider(JSON.parse($(element).attr('data-config')))
              .on('slidechange', function (event, ui) {
                self.setPage(1);

                $.bbq.pushState({price: ui.values.join('-')});

                self.doRequest(false);
              });
        });
      },

      /**
       * @param event
       * @private
       */
      handleFilterItem: function (event) {
        self.disableFilters();
        self.resetPage();

        var $element = $(event.currentTarget);

        self.dispatchEvent('ajaxlist:handleFilterItem', {element: $element});

        var attr = $element.is('select')
            ? $element.data('attribute')
            : $element.closest('[data-attribute]').data('attribute'),
            value = $element.is('select')
                ? $element.val()
                : $element.data('value');

        self.toggleFilterItem(attr, value);

        self.doRequest(false);
      },

      /**
       * @param attr
       * @param value
       */
      toggleFilterItem: function (attr, value) {
        var filter = {},
            params = self.getUrlParams();

        value = value.toString();

        if (_.has(params, attr)) {
          if (_.contains(params[attr], value)) {
            filter[attr] = _.filter(params[attr], function (paramValue) {
              return paramValue !== value;
            });
          } else {
            filter[attr] = params[attr].concat([value]);
          }
        } else {
          filter[attr] = [value];
        }

        $.bbq.pushState(filter);
      },

      /**
       * Retrieve url hash params
       */
      getUrlParams: function () {
        return _.extend({}, self.getAjaxProxyUrlParams(), $.deparam.fragment());
      },

      /**
       * Retrieve url hash params from self.ajaxProxyUrl
       */
      getAjaxProxyUrlParams: function () {
        if (!self.ajaxProxyUrl) {
          return {};
        }

        return $.deparam.fragment(self.ajaxProxyUrl.split('?')[1]) || {};
      },

      /**
       * @returns {Number|number}
       */
      getPage: function () {
        return parseInt($.bbq.getState('page'));
      },

      /**
       * Sets next page index parameter for lazy loading
       */
      setPage: function (page, fromPage) {
        self.page = parseInt(page);
        self.fromPage = fromPage || self.getFromPage();

        $.bbq.pushState({page: self.page, fromPage: self.fromPage});
      },

      /**
       * @returns {Number|number}
       */
      resetPage: function () {
        self.setPage(1, 0);
      },

      /**
       * @returns {Number|number}
       */
      resetFromPage: function () {
        $.bbq.pushState({fromPage: 0});
      },

      /**
       * @returns {Number|number}
       */
      setFromPage: function (fromPage) {
        $.bbq.pushState({fromPage: fromPage});
      },

      /**
       * @returns {Number|number}
       */
      getFromPage: function () {
        return parseInt($.bbq.getState('fromPage')) || 0;
      },

      /**
       * @returns {Element|Array.<Element>}
       */
      getLoadedPages: function () {
        return self.$productList.find('[data-page]').map(function () {
          return $(this).attr('data-page');
        }).get()
      },

      /**
       * @returns {Number|number}
       */
      getFirstLoadedPage: function () {
        return parseInt(_.first(self.getLoadedPages()));
      },

      /**
       * @returns {Number|number}
       */
      getLastLoadedPage: function () {
        return parseInt(_.last(self.getLoadedPages()));
      },

      /**
       * Refresh url hash params excl. unnecessary params
       */
      updateUrl: function () {
        $.bbq.pushState(_.omit(self.getUrlParams(), ['lazy', 'ajax'], 2));
      },

      /**
       * Trigger a page lazy load
       */
      lazyLoadNextPage: function () {
        var isInViewportConfig = {
          tolerance: self.isInViewportTolerance
        };

        if (!self.isEnd &&
            self.$productList.find('.product-item:last').isInViewport(isInViewportConfig)) {
          $window.off('scroll.ajaxlist');

          self.loadNextPage();
        } else if (self.hasScrolled && self.getFirstLoadedPage() > 1 &&
            self.$productList.find('.product-item:first').isInViewport(isInViewportConfig)) {
          $window.off('scroll.ajaxlist');

          self.hasScrolled = false;
          self.loadPreviousPage();
        }
      },

      /**
       * Load previous loaded page
       */
      loadPreviousPage: function () {
        self.insertDirection = 'prepend';
        var previousPage = self.getFirstLoadedPage() - 1;
        self.setFromPage(previousPage - 1);
        self.loadPage(previousPage);
      },

      /**
       * Load next loaded page
       */
      loadNextPage: function () {
        self.insertDirection = 'append';
        var lastPage = self.getLastLoadedPage();
        self.page = lastPage + 1;
        self.setFromPage(lastPage);
        self.loadPage(self.page);
      },

      /**
       * Initialize a page load
       */
      loadPage: function (page) {
        self.setPage(page);
        self.doRequest(true);
      },

      /**
       * Disable filters (while query is running)
       *
       * @returns {undefined}
       */
      disableFilters: function () {
        $(self.navigationItemSelector).off();
      },

      /**
       * @param eventName
       * @param data
       * @returns {jQuery|jQuery}
       */
      dispatchEvent: function (eventName, data) {
        return $document.trigger(eventName, data);
      },

      /**
       *
       * @param selector
       * @param data
       * @returns {*}
       */
      getTemplate: function (selector, data) {
        data = data || {data: {}};
        if (!_.has(data, 'data')) {
          data['data'] = {};
        }

        selector += _.has(data.data, 'template')
            ? $(selector + data.data.template).length ? data.data.template : 'default'
            : 'default';

        if (!_.has(self.compiledTemplates, selector)) {
          self.compiledTemplates[selector] = mageTemplate(selector);
        }

        return self.compiledTemplates[selector](data);
      },

      /**
       * Returns attribute filter template
       * @param data
       * @returns {*}
       */
      getFilterTemplate: function (data) {
        return self.getTemplate(self.filterTmplSelector, data);
      },

      /**
       * Returns toolbar template
       * @param data
       * @returns {*}
       */
      getToolbarTemplate: function (data) {
        return self.getTemplate(self.toolbarTmplSelector, data);
      },

      /**
       * Returns product block template
       * @param data
       * @returns {*}
       */
      getProductTemplate: function (data) {
        return self.getTemplate(self.productTmplSelector, data);
      },

      /**
       * @param el
       * @param html
       */
      setInnerHtml: function (el, html) {
        el.innerHTML = html;
        var scripts = el.getElementsByTagName('script'),
            scriptsClone = [];

        _.each(scripts, function (script) {
          scriptsClone.push(script);
        });

        _.each(scriptsClone, function (currentScript) {
          var s = document.createElement('script');
          _.each(currentScript.attributes, function (attr) {
            s.setAttribute(attr.name, attr.value);
          });

          s.appendChild(document.createTextNode(currentScript.innerHTML));
          currentScript.parentNode.replaceChild(s, currentScript);
        });
      }
    });
  }
);

