/*jshint browser:true jquery:true*/
/*global confirm:true*/
define('Kalkhoff_CookieManager/js/components/cookie-manager',[
    'jquery',
    'baseCookieManager',
    'storageModel',
    'mage/translate'
], function ($, Component, StorageModel, $t) {
    'use strict';

    return Component.extend({
        defaults: {
            cookies: [],
            settingsButtonFooter: '#show-cookie-settings-block',
            enableFunctionalCookiesButton: "#allow-functional-cookies",
            cookieAllowButtonSelector: "#btn-cookie-allow",
            cookieNoticeBlock: '#notice-cookie-block',
            levels: [
                {
                    name: 'functional',
                    allowed: true,
                    disabled: true
                },
                {
                    name: 'analytics',
                    allowed: true,
                    disabled: false
                },
                {
                    name: 'advertising',
                    allowed: true,
                    disabled: false
                },
                {
                    name: 'marketing',
                    allowed: true,
                    disabled: false
                }
            ]
        },

        initialize: function () {
            this._super();

            const self = this;

            if (!StorageModel.get('user_allowed_save_cookie')) {
                let levels = [];
                for (let i = 0; i < self.levels.length; i++) {
                    self.levels[i].allowed = true;

                    levels[i] = {};
                    levels[i].name = self.levels[i].name;
                    levels[i].allowed = true;

                    if (self.levels[i].name !== 'functional') {
                        levels[i].allowed = false;
                    }
                }

                //self.pushDataLayer('cookie_consent_status', levels);

            }

            if (StorageModel.get('user_allowed_save_cookie')) {
                // only push if use has consent
                // self.pushDataLayer('cookie_consent_status');
            }
            $('body').on('click', self.cookieAllowButtonSelector, function (){
                // self.pushDataLayer('cookie_consent_update');
            });

            $(document).ready($(self.settingsButtonFooter).on('click', function (e) {
                e.preventDefault();
                Cookiebot.show();
            }));

            $(document).ready($(self.enableFunctionalCookiesButton).on('click', function () {
                self.setFunctionalOnly();
            }));
        },

        setFunctionalOnly: function () {
            for (let i = 0; i < this.levels.length; i++) {
                let switchElement = $('.cookie-settings .item[value="' + this.levels[i].name + '"] .switch');

                if (this.levels[i].name === 'functional') {
                    this.setLevel(this.levels[i].name, true);
                    switchElement.attr('data-switch', 'on');
                } else {
                    this.setLevel(this.levels[i].name, false);
                    switchElement.attr('data-switch', 'off');
                }
            }
            // this.pushDataLayer('cookie_consent_update');

            this.saveSettings();
        },

        /**
         * @param name
         * @param checked
         *
         * @returns {*}
         */
        setLevel: function (name, checked) {
            if (checked === true) {
                this.triggerDatalayer(name);
            }

            this._super();
        },

        /**
         * @param name
         */
        triggerDatalayer: function (name) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'event': 'cookie_consent_' + name
            });
        },

        setLevelBySwitch: function (name, element) {
            this.toggleSwitch(element);
            this.setLevel(name, $(element).data('switch') === 'on');
        },

        /**
         * Toggle a switch in the template
         *
         * @param switchElement
         */
        toggleSwitch: function (switchElement) {
            if ($(switchElement).attr('data-switch-disabled') === 'disabled') {
                return;
            }

            if ($(switchElement).attr('data-switch') === 'on') {
                $(switchElement).attr('data-switch', 'off');
            } else {
                $(switchElement).attr('data-switch', 'on');
            }
        },

        /**
         * Push an event to GTM with levels as separate variable
         *
         * @param event
         */
        pushDataLayer: function (event, levels = this.levels) {
            window.dataLayer = window.dataLayer || [];

            let dataLayer = {
                'event': event
            };
            for (let i = 0; i < levels.length; i++) {
                dataLayer[levels[i].name] = levels[i].allowed;
            }

            window.dataLayer.push(dataLayer);
        },

        /**
         * Override from Base Cookie Manager to prevent `expires: -1` from crashing
         */
        filterCookies: function () {
            for (var i = 0; i < this.cookies.length; i++) {
                for (var y = 0; y < this.levels.length; y++) {
                    if (this.cookies[i].level === this.levels[y].level && this.levels[y].allowed === false) {
                        StorageModel.set(this.cookies[i].name, 'empty', {
                            domain: this.cookies[i].domain,
                            path: '/',
                            expires: null
                        });
                    }
                }
            }
        },

        /**
         * Dummy file so strings are translatable. Magento searches for strings using the monsterous regex \$t\((?s)[^'"]*?(["'])(.+?)\1(?s).*?\)
         * in all JS files, and adds those to js-translations
         *
         * Since our cookie titles are in cookies.xml, and those are not picked up...
         * We just copy paste them here. Ugly, but efficient
         */
        dummyFunctionForDummies: function () {
            $t('Functional');
            $t('Basic cookies necessary for the site to work normally');
            $t('Analytics');
            $t('Cookies that track how users behave on our website');
            $t('Social media');
            $t('These cookies allow social media network to track your internet behavior');
            $t('Advertising');
            $t('Cookies that will allow advertising platforms to track your behavior');
        }
    });
});

