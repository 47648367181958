/**
 * Black transparent overlay when opening menu's / modals
 *
 * @author Martijn Rijnja <martijn.rijnja@pon.com>
 */

define('js/black-overlay',[
    'jquery'
], function ($) {
    "use strict";

    var overlay = {};
    var options = {
        overlayElement: "<div class='black-overlay'></div>",
        overlayClass: '.black-overlay',
        body: $('body'),
        duration: 2000
    };

    overlay.toggleOverlay = toggleOverlay;
    overlay.initialize = initialize;

    function initialize(container) {
        if (!options.body.find(options.overlayClass).length) {
            options.body.append(options.overlayElement);
        }

        if (container && !$(container).find(options.overlayClass).length) {
            $(container).append(options.overlayElement);
        }

        $(options.overlayClass).on('click', function () {
            toggleOverlay('close');
            $(document).trigger('closeMenus');
        });
    }

    function toggleOverlay(mode, container) {
        var overlay = options.body.find(options.overlayClass);

        if (container) {
            overlay = $(container).find(options.overlayClass);
        }

        switch (mode) {
            case 'close':
                overlay.fadeOut();
                options.body.removeClass('no-scroll');
                break;
            case 'show':
                overlay.fadeIn();
                options.body.addClass('no-scroll');
                break;
            default:
                overlay.fadeToggle();
                options.body.toggleClass('no-scroll');
        }
    }

    return overlay;

});

