define('js/plyr',[
    'plyr'
], function(){

    var plyrComponent = function(config) {
        var playerId = config.playerId;
        var plyrOptions = config.options;

        if(!plyrOptions){
            plyrOptions = {
                controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'fullscreen'],
                youtube: {
                    showinfo: 0,
                    rel: 0,
                    modestbranding: 0
                }
            };
        }


        require(["Plyr"], function (Plyr) {
            // https://github.com/sampotts/plyr/#options
            var player = new Plyr(playerId, plyrOptions);

            if(plyrOptions.autoplay===true) {
                player.muted = true;
                player.play();
            }
            // Expose player so it can be used from the console
            if(!window.player){
                window.player = {};
            }
            window.player[playerId] = player;

        });
    }
    return plyrComponent;
})
;
