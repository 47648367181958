define('Kalkhoff_Tooltips/js/load-tooltips',[
    'jquery',
    'mage/translate',
    'jquery-ui-modules/widget',
    'Magento_Ui/js/modal/modal',
    'Magento_Swatches/js/swatch-renderer'
], function ($, $t) {
    'use strict';

    $.widget('mage.loadTooltips', {
        options: {
            swatchAttribute: '.swatch-attribute',
            filterAttribute: '#pon-toolbar .filter-options [data-attribute]'
        },

        /**
         *
         * @private
         */
        _create: function () {
            this._loadTooltips();
        },

        /**
         *
         * @private
         */
        _loadTooltips: function () {
            var self = this;
            $.ajax({
                url: self.options.spConfig.sectionLoadUrl,
                cache: true,
                dataType: 'json',
                showLoader: false
            }).done(function (response) {
                self.options.tooltipsContent = response;
                self._addTooltipToAttribute();
                self._addTooltipToFilterableAttribute();
                $(document).on('ajaxlist:rebuild:after', function (event, data) {
                    self._addTooltipToFilterableAttribute();
                });
            });
        },

        /**
         *
         * @private
         */
        _addTooltipToAttribute: function () {
            var self = this;
            $($(self.options.swatchAttribute)).each(function() {
                var attributeCode = $(this).attr('attribute-code'),
                    tooltipAttributeCode = 'tooltip_' + attributeCode,
                    tooltipContent = self.options.tooltipsContent[tooltipAttributeCode],
                    targetElement = $('.swatch-attribute-options', this);
                if (tooltipContent) {
                    targetElement.append('<a href="#" class="show-tooltip" data-tooltip-attribute data-modal-trigger  data-title="' + encodeURIComponent( tooltipContent.title ) + '" data-content="' + encodeURIComponent ( tooltipContent.content ) + '">?</a>');
                }
            });
        },

        /**
         *
         * @private
         */
        _addTooltipToFilterableAttribute: function () {
            var self = this;
            $($(self.options.filterAttribute)).each(function () {
                var attributeCode = $(this).attr('data-attribute'),
                    tooltipAttributeCode = 'tooltip_' + attributeCode,
                    tooltipContent = self.options.tooltipsContent[tooltipAttributeCode],
                    targetElement = $('h5', this);
                if (tooltipContent) {
                    targetElement.append('<span class="show-tooltip show-tooltip--questionmark" data-tooltip-attribute data-modal-trigger data-title="' + encodeURIComponent(tooltipContent.title) + '" data-content="' + encodeURIComponent(tooltipContent.content) + '">?</span>');
                }
            });
        }

    });

    return $.mage.loadTooltips;
});

