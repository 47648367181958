define('Kalkhoff_BlueFootEnhancements/js/visual-header',['jquery'], function ($) {
    const BREAKPOINT = 750;
    const DESKTOP_VIDEO_SELECTOR = '.visual-header__video--desktop';
    const MOBILE_VIDEO_SELECTOR = '.visual-header__video--mobile';
    const HEADER_CTA_1_SELECTOR = '.visual-header__cta--1';
    const HEADER_CTA_1_CLICKABLE_CLASS = 'clickable';
    const FOLDABLE_LIST_TRIGGER_SELECTOR = '.foldable__trigger';

    class VisualHeader {
        constructor(element) {
            this.activeVideo = null;
            this.desktopVideo = element.querySelector(DESKTOP_VIDEO_SELECTOR);
            this.mobileVideo = element.querySelector(MOBILE_VIDEO_SELECTOR);
            this.headerCta1Selector = element.querySelector(HEADER_CTA_1_SELECTOR)

            this.startCorrectVideo = this.startCorrectVideo.bind(this);
            this.setVideoSource = this.setVideoSource.bind(this);
            this.bindTestivalListTrigger = this.bindTestivalListTrigger.bind(this);

            this.startCorrectVideo();

            const foldableListTrigger = document.querySelector(FOLDABLE_LIST_TRIGGER_SELECTOR);

            if (foldableListTrigger) {
                this.bindTestivalListTrigger(foldableListTrigger);
            }

            if (this.desktopVideo && this.mobileVideo) {
                $(window).resize(this.startCorrectVideo);
            }
        }

        bindTestivalListTrigger(target) {
            this.headerCta1Selector.addEventListener('click', (e) => {
                if (!$(this.headerCta1Selector).hasClass(HEADER_CTA_1_CLICKABLE_CLASS)) {
                    e.preventDefault();
                }

                if (!target.parentNode.classList.contains('open')) {
                    target.click();
                } else {
                    target.parentNode.animate([
                        { transform: 'translateY(0)' },
                        { transform: 'translateY(-4px)' },
                        { transform: 'translateY(0)' },
                        { transform: 'translateY(-4px)' },
                        { transform: 'translateY(0)' }
                    ], { duration: 450, iterations: 1, easing: 'linear' });
                }
            });
        }

        startCorrectVideo() {
            if (this.desktopVideo && !this.mobileVideo) {
                this.setVideoSource(this.desktopVideo);
                this.desktopVideo.play();

                return;
            }

            if (this.mobileVideo && !this.desktopVideo) {
                this.setVideoSource(this.mobileVideo);
                this.mobileVideo.play();

                return;
            }

            if ($(window).width() >= BREAKPOINT) {
                this.setVideoSource(this.desktopVideo);

                if (this.desktopVideo && this.desktopVideo.paused) {
                    this.mobileVideo.pause();
                    this.desktopVideo.play();
                    this.activeVideo = this.desktopVideo;
                }
            } else {
                this.setVideoSource(this.mobileVideo);

                if (this.mobileVideo && this.mobileVideo.paused) {
                    this.desktopVideo.pause();
                    this.mobileVideo.play();
                    this.activeVideo = this.mobileVideo;
                }
            }
        }

        setVideoSource(video) {
            if (!video.src) {
                video.src = video.dataset.src;
            }
        }
    }

    return function (config, element) {
        if (!config.hasVideo && !config.hasMobileVideo) {
            return;
        }

        const visualHeader = new VisualHeader(element);
    }
});

