define(
    'Kalkhoff_BlueFootEnhancements/js/bike-usage-carousel',[
    'jquery',
    'slick'
    ],
    function ($, slick) {
        'use strict';

        $.widget(
            'mage.bikeusageCarousel',
            {

                options: {
                    carouselSelector: '.bike-usage-carousel__slider',
                    slidesSelector: '.bike-usage-carousel__slide',
                    paginationSelector: '.bike-usage-carousel__pagination',
                    paginationItemsSelector: '.bike-usage-carousel__pagination li',
                    slideInClass: 'slideIn',
                    activeClass: 'slide--active',
                    useAdaptiveHeight: false,
                    sliderData: []
                },

                _create: function () {
                    this.initElements();
                    this.initSlickSlider();
                    this.initEventHandlers();
                },

                initElements: function () {
                    this.$body            = $('body');
                    this.$carousel        = this.element.find(this.options.carouselSelector);
                    this.$slides          = this.element.find(this.options.slidesSelector);
                    this.$navigationItems = this.options.paginationItemsSelector;

                },

                initEventHandlers: function () {
                    var self     = this;
                    var oldSlide = '';
                    var newSlide = 0;

                    self.$carousel.on(
                        'beforeChange',
                        function (event, slick, current, next) {
                            oldSlide = current;

                            self.element
                            .find(self.options.slidesSelector + ':eq(' + next + ')')
                            .addClass(self.options.slideInClass);
                        }
                    );

                    self.$carousel.on(
                        'afterChange',
                        function () {
                            self.element
                            .find(self.options.slidesSelector + ':eq(' + oldSlide + ')')
                            .removeClass(self.options.slideInClass);

                            if (self.options.useAdaptiveHeight === true) {
                                self.sliderAdaptiveHeight();
                            }
                        }
                    );

                    self.$body.on(
                        'mouseover',
                        this.$navigationItems,
                        function (e) {
                            newSlide = $(this).index();

                            self.element
                            .find(self.options.paginationItemsSelector)
                            .removeClass(self.options.activeClass);
                            self.element
                            .find(self.options.paginationItemsSelector + ':eq(' + newSlide + ')')
                            .addClass(self.options.activeClass);

                            let slickObj = $(self.$carousel).slick('getSlick');
                            slickObj.slickGoTo(newSlide);
                        }
                    );

                    self.$body.on(
                        'click',
                        this.navigationItems,
                        function (e) {
                            e.stopPropagation();
                        }
                    )
                },

                initSlickSlider: function () {
                    var self       = this;
                    var sliderData = self.options.sliderData;

                    self.$carousel.on(
                        'init',
                        function () {
                            self.element
                            .find(self.options.slidesSelector + ':eq(0)')
                            .addClass(self.options.slideInClass);

                            self.element
                            .find(self.options.paginationItemsSelector + ':eq(0)')
                            .addClass(self.options.activeClass);
                        }
                    );

                    self.$carousel.slick(
                        {
                            draggable: false,
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            arrows: false,
                            dots: true,
                            fade: true,
                            swipe: false,
                            speed: 200,
                            appendDots: self.options.paginationSelector,
                            lazyLoad: 'ondemand',
                            customPaging: function (slider, i) {
                                return '<a href="' + sliderData[i].link_url + '"><h4>' + sliderData[i].link_text + '</h4></a>';
                            }
                        }
                    );
                },

                sliderAdaptiveHeight: function () {
                    var self = this;
                    self.$carousel.find('.slick-slide').height('0');
                    self.$carousel.find('.slick-slide.slick-active, .slick-list').height('auto');
                }
            }
        );

        return $.mage.bikeusageCarousel;
    }
);

