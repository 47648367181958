/*jshint browser:true jquery:true*/
/*global confirm:true*/
define(
    'Pon_CookieManager/js/components/base-cookie-manager',[
        'jquery',
        'uiComponent',
        'underscore',
        'storageModel',
        'mage/template',
        'mage/cookies'
    ],
    function ($, Component, _, StorageModel) {
        'use strict';
        return Component.extend({
            defaults: {
                popupTemplate: '#cookie-manager-popup',
                settingsButton: '#btn-cookie-settings',
                allowCookiesButton: '#btn-cookie-allow',
                cookies: [],
                levels: [
                    {
                        name: 'functional',
                        allowed: true
                    },
                    {
                        name: 'analytics',
                        allowed: true,
                    },
                    {
                        name: 'advertising',
                        allowed: false,
                    },
                    {
                        name: 'marketing',
                        allowed: false,
                    }
                ],
                allowedCookies: {},
                cookieName: 'CookieLevel',
                fallbackCookie: 'system',
                denyCookieGetter : false
            },

            /**
             * Constructor
             */
            initialize: function () {
                this._super();
                var self = this;

                this.initModel();

                this.denyCookieGetter = false;
                this.intializePropertyDescriptor();
                this.initCookieInterceptor();

                // TODO: Maybe only trigger when click on settings button
                var cookies = JSON.parse(StorageModel.get(this.cookieName));
                if (cookies) {
                    _.each(cookies, function(value, key) {
                        self.setLevel(key, value);
                    });
                }

                // Accept cookies and hide notice bar
                $(this.allowCookiesButton).on('click', function () {
                    self.saveSettings();
                });

                // Show cookie settings popup
                $(document).ready($(this.settingsButton).on('click', function () {
                    self.changeLevel();
                    $('#notice-cookie-block').hide();
                }));

                if (!StorageModel.get(this.cookieName)) {
                    this.saveCookieLevels();
                }
                this.filterCookies();
            },

            initModel: function() {
                // child implementation
            },

            /**
             * Overrides the default getter and setter for cookies while keeping a local backup of the functions
             * in order to be able to have complete control over which cookies are get
             */
            initCookieInterceptor: function () {
                this.cookieGetter = Object.getPropertyDescriptor(document, 'cookie').get;
                this.cookieSetter = Object.getPropertyDescriptor(document, 'cookie').set;

                var self = this;
                if (!document.__defineGetter__) {
                    Object.defineProperty(document, 'cookie', {
                        get: function () {
                            return self.getOrDenyCookie();
                        },
                        set: function (cookie) {
                            self.setOrDenyCookie(cookie);
                        }
                    });
                } else {
                    document.__defineGetter__("cookie", function () {
                        return self.getOrDenyCookie();
                    });
                    document.__defineSetter__("cookie", function (cookie) {
                        self.setOrDenyCookie(cookie);
                    });
                }
            },

            setOrDenyCookie: function (cookie) {
                if (!this.isAllowed( this.getCookieName(cookie) ) || !this.cookieSetter) {
                    return;
                }

                this.cookieSetter.call(document, cookie);
            },

            getCookieName:function(cookie){
                var parts = cookie.split('; ');
                var nameAndValue = parts[0].split('=');
                return nameAndValue[0];
            },

            getOrDenyCookie: function () {
                if (this.denyCookieGetter || !this.cookieSetter) {
                    return '';
                }

                return this.cookieGetter.call(document);
            },

            isChecked: function (name) {
                for (var i = 0; i < this.levels.length; i++) {
                    if (name === this.levels[i].name) {
                        return this.levels[i].allowed;
                    }
                }
            },

            /**
             * Check whether the checkbox should be disabled by default
             */
            isDisabled: function (element) {
                return element.disabled;
            },

            /**
             * Save cookie levels to browser
             */
            saveCookieLevels: function () {
                for (var i = 0; i < this.levels.length; i++) {
                    this.allowedCookies[this.levels[i].name] = this.levels[i].allowed;
                }

                StorageModel.set(this.cookieName, JSON.stringify(this.allowedCookies), {
                    domain: this.configCookieDomain,
                    path: '/',
                    expires: this.getCookieExpiryDate(),
                    raw: true
                });
            },
            /**
             * Adds 30 days expire to cookie
             *
             * @returns {Date}
             */
            getCookieExpiryDate: function () {
                var date = new Date();
                date.setTime(date.getTime() + (365 * 24 * 60 * 60 * 1000));
                return date;
            },

            /**
             * Save cookie settings and hide the notices
             */
            saveSettings: function () {
                this.saveCookieLevels();
                this.filterCookies();

                // TODO: make these values dynamic (get from Cookie helper)
                StorageModel.set('user_allowed_save_cookie', 1, {
                    domain: this.configCookieDomain,
                    path: '/',
                    expires: this.getCookieExpiryDate(),
                    raw: true
                });
                this.hideCookieBlock();
            },

            /**
             * Hide the modal
             */
            hideCookieBlock: function () {
                // child implementation
            },

            /**
             * Sets current cookie level
             *
             * @param name
             * @param checked
             * @returns {boolean}
             */
            setLevel: function (name, checked) {
                for (var i = 0; i < this.levels.length; i++) {
                    if (this.levels[i].name === name) {
                        this.levels[i].allowed = checked;
                        break;
                    }
                }

                return true;
            },

            /**
             * Opens modal to change level
             */
            changeLevel: function () {
                // child implementation
            },

            /**
             * Returns true if cookie is allowed by level selection
             * Falls back to 'system' (level 0), so it is allowed when not listed
             *
             * @param cookieName
             *
             * @returns {boolean}
             */
            isAllowed: function (cookieName) {
                var cookie;
                for (var i = 0; i < this.cookies.length; i++) {
                    if (this.cookies[i].name === cookieName) {
                        cookie = this.cookies[i];
                        break;
                    }
                }
                if (cookie !== undefined) {
                    for (var y = 0; y < this.levels.length; y++) {
                        if (this.levels[y].level === cookie.level) {
                            return this.levels[y].allowed;
                        }
                    }
                    return true;
                }
                return this.isAllowed(this.fallbackCookie);
            },

            filterCookies: function () {
                // TODO: Make pretty
                for (var i = 0; i < this.cookies.length; i++) {
                    for (var y = 0; y < this.levels.length; y++) {
                        if (this.cookies[i].level === this.levels[y].level && this.levels[y].allowed === false) {
                            StorageModel.set(this.cookies[i].name, 'empty', {
                                domain: this.cookies[i].domain,
                                path: '/',
                                expires: -1
                            });
                        }
                    }
                }
            },

            /**
             * Makes sure that getOwnPropertyDescriptor works in internet explorer 11.
             * @summary getOwnPropertyDescriptor shim for IE 11
             *
             * Used to get the getter and setter of cookie in the document object.
             * @see initCookieInterceptor
             *
             * @see https://gist.github.com/WebReflection/3373484
             * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/getOwnPropertyDescriptor
             */
            intializePropertyDescriptor: function () {
                !function (Object, getPropertyDescriptor, getPropertyNames) {
                    if (!(getPropertyDescriptor in Object)) {
                        var getOwnPropertyDescriptor = Object.getOwnPropertyDescriptor;
                        Object[getPropertyDescriptor] = function getPropertyDescriptor(o, name) {
                            var proto = o, descriptor;
                            while (proto && !(
                                    descriptor = getOwnPropertyDescriptor(proto, name))
                                ) proto = proto.__proto__;
                            return descriptor;
                        };
                    }

                    if (!(getPropertyNames in Object)) {
                        var getOwnPropertyNames = Object.getOwnPropertyNames, ObjectProto = Object.prototype,
                            keys = Object.keys;
                        Object[getPropertyNames] = function getPropertyNames(o) {
                            var proto = o, unique = {}, names, i;
                            while (proto != ObjectProto) {
                                for (names = getOwnPropertyNames(proto), i = 0; i < names.length; i++) {
                                    unique[names[i]] = true;
                                }
                                proto = proto.__proto__;
                            }
                            return keys(unique);
                        };
                    }
                }(Object, "getPropertyDescriptor", "getPropertyNames");
            }

        });
    }
);

