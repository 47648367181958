define('productGallery',[
    'jquery',
    'slick',
    'galleryZoom',
    'matchMedia'
], function ($, slick) {
    'use strict';

    $.widget('mage.pdpGallery', {
        options: {
            zoomClass: 'zoom',
            zoomedInClass: 'zoom-active',
            zoomIcon: '.zoom__icon',
            hiddenClass: 'hidden',
            carouselSelector: '.product-gallery__items--main',
            modalCarouselSelector: '.product-gallery__items--modal',
            modalSelectorId: 'product-gallery-modal',
            modalTriggersAttribute: 'galleryModalTrigger',
            modalVisibleClass: 'visible',
            openModalBodyClass: 'modal-open',
            slickSlideSelector: '.slick-slide',
            currentSlideClass: 'slick-current',
            activeModalSlideSelector: '.product-gallery__items--modal .slick-active img',
            thumbsSelector: '.product-gallery__thumbnails--main',
            hiddenThumbsClass: 'product-gallery__thumbnails--hidden',
        },

        _create: function () {
            this.initElements();
            this.initZoom();
            this.initSlickSliders();
            this.initModalTriggers();
        },

        initElements: function () {
            this.$gallery = this.element.find(this.options.carouselSelector);
            this.$modalGallery = this.element.find(this.options.modalCarouselSelector);
            this.$modal = document.getElementById(this.options.modalSelectorId);
            this.$zoomIcon = document.querySelector(this.options.zoomIcon);
            this.$thumbnails = this.element.find(this.options.thumbsSelector);
        },

        initZoom: function () {
            const self = this;

            zoom({}, {scaleDefault: 4}, () => self.zoomCallback());
        },

        initSlickSliders: function () {
            const self = this;
            const allSliderSelectors = `${self.options.thumbsSelector}, ${self.options.carouselSelector}, ${self.options.modalCarouselSelector}`;

            $(self.$gallery).slick({
                swipe: false,
                arrows: false,
                infinite: false,
                slidesToShow: 1,
                slidesToScroll: 1,
                asNavFor: allSliderSelectors,
            }).on('init', () => self.initModalTriggers());

            $(self.$thumbnails).slick({
                slidesToShow: 3,
                slidesToScroll: 1,
                swipe: false,
                infinite: false,
                centerMode: true,
                focusOnSelect: true,
                variableWidth: true,
                asNavFor: allSliderSelectors,
                prevArrow: '<a href="#" class="slick-arrow-prev"></a>',
                nextArrow: '<a href="#" class="slick-arrow-next"></a>',
            }).on('reInit', (event, slick) => {
                self.hideThumbnails(event, slick);
                self.resetIndexesAfterFilter(allSliderSelectors);
            });

            $(self.$modalGallery).on('afterChange', () => self.loadHighRes());
            $(self.$modalGallery).on('beforeChange', () => {
                self.resetZoom();
                self.zoomCallback(true);
            });
            $(self.$modalGallery).slick({
                swipe: true,
                arrows: true,
                infinite: false,
                slidesToShow: 1,
                slidesToScroll: 1,
                asNavFor: allSliderSelectors,
                prevArrow: '<a href="#" class="slick-arrow-prev"></a>',
                nextArrow: '<a href="#" class="slick-arrow-next"></a>',
            });

            const slickGallery = $(self.options.carouselSelector).slick('getSlick'),
                slickModalGallery = $(self.options.modalCarouselSelector).slick('getSlick'),
                slickGalleryThumbs = $(self.options.thumbsSelector).slick('getSlick'),
                simpleId = $(slickGallery.$slides.get(0)).data('simples');

            [slickGallery, slickModalGallery, slickGalleryThumbs].map((slickGallery) => {
                slickGallery.slickUnfilter();
                slickGallery.slickFilter(`[data-simples*="${simpleId}"]`);

                return true;
            });
        },

        resetIndexesAfterFilter: function(allSliderSelectors) {
            const self = this,
                $slider = $(allSliderSelectors);

            $slider.each(function(index, slide) {
                let activeSlideIndex,
                    slideId = 0;

                $(`${self.options.slickSlideSelector}`, slide).each(function() {
                    $(this).attr("data-slick-index", slideId);
                    slideId += 1;

                    if (index == 0 && $(this).hasClass(`${self.options.currentSlideClass}`)) {
                        activeSlideIndex = slideId;
                    }

                    if (index == 1 && slideId == activeSlideIndex) {
                        $(this).addClass(`${self.options.currentSlideClass}`);
                    }
                })
            })

            $slider.slick('slickGoTo', 0, true);
        },

        hideThumbnails: function (event, slick) {
            event.target.classList.toggle(this.options.hiddenThumbsClass, slick.slideCount === 1);
        },

        initModalTriggers: function () {
            const self = this;

            document.addEventListener('click', (e) => {
                if (e.target.dataset.hasOwnProperty(self.options.modalTriggersAttribute)) {
                    self.toggleModal();
                }
            });

            document.addEventListener('keyup', (e) => {
                if (
                    document.querySelector('body').classList.contains(self.options.openModalBodyClass) &&
                    self.$modal.classList.contains(self.options.modalVisibleClass) &&
                    e.key === "Escape"
                ) {
                    self.toggleModal();
                }
            });
        },

        zoomCallback: function (forceEnable = false) {
            const self = this,
                activeModalSlideImage = document.querySelector(self.options.activeModalSlideSelector);

            if (forceEnable) {
                $(self.$modalGallery).slick('slickSetOption', 'swipe', true);
                $(self.$modalGallery).find('.slick-arrow').show();

                return;
            }

            if (activeModalSlideImage) {
                const notZoomedIn = !activeModalSlideImage.parentNode.classList.contains(self.options.zoomedInClass);

                $(self.$modalGallery).slick('slickSetOption', 'swipe', notZoomedIn);
                $(self.$modalGallery).find('.slick-arrow').toggle(notZoomedIn);
            }
        },

        modalIsOpen: function () {
            const self = this;

            return document.querySelector('body').classList.contains(self.options.openModalBodyClass);
        },

        toggleZoomIcon: function (activeSlide) {
            const self = this;

            if (!self.$zoomIcon) {
                return;
            }

            if (activeSlide.parentNode.classList.contains(self.options.zoomClass)) {
                self.$zoomIcon.classList.remove(self.options.hiddenClass);
            } else {
                self.$zoomIcon.classList.add(self.options.hiddenClass);
            }
        },

        loadHighRes: function (currentSlide) {
            const self = this,
                activeModalSlideImage = document.querySelector(self.options.activeModalSlideSelector);

            if (activeModalSlideImage) {
                self.toggleZoomIcon(activeModalSlideImage);

                const desktopFullSizeImage = activeModalSlideImage.dataset.fullsizeDesktop,
                    mobileFullSizeImage = activeModalSlideImage.dataset.fullsizeMobile;

                if (!desktopFullSizeImage && !mobileFullSizeImage) {
                    return;
                }

                if (mobileFullSizeImage) {
                    mediaCheck({
                        media: '(min-width: 768px)',
                        entry: () => self.setImageSource(activeModalSlideImage, desktopFullSizeImage),
                        exit: () => {
                            if (activeModalSlideImage.src !== desktopFullSizeImage) {
                                self.setImageSource(activeModalSlideImage, mobileFullSizeImage)
                            }
                        }
                    });
                } else {
                    self.setImageSource(activeModalSlideImage, desktopFullSizeImage);
                }
            }
        },

        setImageSource: function (activeModalSlide, fullSizeImage) {
            const self = this;

            if (activeModalSlide.src) {
                self.replaceImageWithHighRes(activeModalSlide, fullSizeImage);
            } else {
                activeModalSlide.onload = () => self.replaceImageWithHighRes(activeModalSlide, fullSizeImage);
            }
        },

        replaceImageWithHighRes: function (activeModalSlide, replaceImage) {
            const img = new Image();

            img.src = replaceImage;
            img.onload = () => {
                if (img.src !== activeModalSlide.src) {
                    activeModalSlide.src = img.src;
                }
            }
        },

        resetZoom: function () {
            const self = this,
                activeModalSlideImage = document.querySelector(self.options.activeModalSlideSelector);

            if (!activeModalSlideImage) {
                return;
            }

            activeModalSlideImage.dataset.scale = 1;
            activeModalSlideImage.dataset.translateY = 1;
            activeModalSlideImage.dataset.translateX = 1;
            activeModalSlideImage.style.transform = null;
            activeModalSlideImage.parentNode.classList.remove(self.options.zoomedInClass);
            $(self.$modalGallery).slick('slickSetOption', 'swipe', true);
            $(self.$modalGallery).slick('slickSetOption', 'arrows', true);
        },

        toggleModal: function () {
            const self = this;

            self.$modal.classList.toggle(self.options.modalVisibleClass);
            document.querySelector('body').classList.toggle(self.options.openModalBodyClass);

            if (!this.modalIsOpen()) {
                $(self.$gallery).slick('slickGoTo', 0, true);
            } else {
                self.loadHighRes();
            }
        },
    });

    return $.mage.pdpGallery;
});

