define('js/subnav',[
    'jquery'
], function ($) {
    if (!$('.subnav')) {
        return;
    }

    $('.subnav a[href*="#"]').not('[href="#"]').click(function (event) {
        let target = $(this.hash);
        if (target.length) {
            event.preventDefault();

            const offset = $('.top-wrapper').height() + $('.subnav').height();

            $('html, body').animate({
                scrollTop: target.offset().top - offset
            }, 500);
        }
    });
})
;
