define('Ponbike_Compare/js/dataSeoHref',[
    'jquery',
    'jquery-ui-modules/widget'
], function ($) {
    $.widget('ponbike.dataSeoHref', {

        options: {},

        _create: function () {
            this.bindSeoHrefNav();
        },

        bindSeoHrefNav: function () {
            $(this.element).on('click', this.seoHrefNav.bind(this));
        },

        seoHrefNav: function (event) {
            const element = $(this.element);
            const href = element.data('seo-href')
                ? element.data('seo-href')
                : element.attr('href');

            if (href) {
                event.preventDefault();
                window.location.href = href;
            }
        }
    });

    return $.ponbike.dataSeoHref;
});

