define('js/topnav',[], function () {
  const mobileMenuOpen = document.querySelector('.c-topnav__mobile-trigger'),
    mobileMenuTriggers = document.querySelectorAll('[data-mobiletrigger]'),
    mobileMenuSearchTrigger = document.querySelector('[data-mobilesearch]'),
    submenuSearch = document.querySelector('.c-topnav__main-item.search'),
    mobileMenuContent = document.querySelector('.c-topnav__list'),
    topNavElement = document.querySelector('.c-topnav'),
    topWrapperElement = document.querySelector('.top-wrapper'),
    submenuVisual = document.querySelector('.c-topnav__submenu-visual figure'),
    submenuVisualTitle = submenuVisual.querySelector('figcaption');

  if (submenuVisual) {
    initSubmenuVisual();
  }

  if (submenuVisualTitle) {
    initSubmenuTitle();
  }

  if (topWrapperElement) {
    initScrollLogic();
  }

  bindMobileTriggers();
  bindOverlayTrigger();

  function bindMobileTriggers() {
    bindMobileSubmenuLogic();
    bindMobileMenuTriggers();
    bindMobileSearchTrigger();
  }

  function isMobileSize() {
    return window.innerWidth <= 1280;
  }

  function hasNoTransparentNavigation() {
      return !document.body.classList.contains('headertype--transparent_light')
          && !document.body.classList.contains('headertype--transparent_dark');
  }

  function bindOverlayTrigger() {
    const submenuOverlay = document.querySelector('.c-topnav__submenu--overlay');
    if(!submenuOverlay) {
      return;
    }

    submenuOverlay.addEventListener('click', () => {
      if (isMobileSize()) {
        submenuSearch.classList.remove('is-open');
        mobileMenuOpen.classList.remove('c-topnav__mobile-trigger--open');
        mobileMenuContent.classList.remove('c-topnav__list--open', 'search-open');
        document.body.classList.remove('modal-open', 'mobile-menu-open', 'mobile-search-open');
        mobileMenuSearchTrigger.classList.remove('c-topnav__mobile-search--open');
      }
    })
  }

  function initScrollLogic() {
    topWrapperElement.classList.toggle('has-topbar', topWrapperElement.querySelector('.topbar'));

    if(hasNoTransparentNavigation()) {
        window.addEventListener('scroll', () => {
          const toggleClass = window.scrollY > 0;
          topWrapperElement.classList.toggle('scrolled', toggleClass);
          topNavElement.classList.toggle('c-topnav--small', toggleClass);
        });
    }
  }

  function bindMobileSearchTrigger() {
    if (!mobileMenuSearchTrigger) {
      return;
    }

    mobileMenuSearchTrigger.addEventListener('click', () => {
      if (isMobileSize()) {
        if (document.body.classList.contains('mobile-menu-open')) {
          document.body.classList.remove('mobile-menu-open');
          mobileMenuContent.classList.remove('c-topnav__list--open');
          mobileMenuOpen.classList.remove('c-topnav__mobile-trigger--open');
        }

        document.body.classList.toggle('mobile-search-open');

        const searchIsOpen = document.body.classList.contains('mobile-search-open');
        document.body.classList.toggle('modal-open', searchIsOpen);
        submenuSearch.classList.toggle('is-open', searchIsOpen);
        mobileMenuContent.classList.toggle('search-open', searchIsOpen);
        mobileMenuSearchTrigger.classList.toggle('c-topnav__mobile-search--open', searchIsOpen);
      }
    });
  }

  function bindMobileMenuTriggers() {
    if (!mobileMenuTriggers) {
      return;
    }

    mobileMenuTriggers.forEach(trigger => {
      trigger.addEventListener('click', () => {
        if (isMobileSize()) {
          if (document.body.classList.contains('mobile-search-open')) {
            document.body.classList.remove('mobile-search-open');
            submenuSearch.classList.remove('is-open');
            mobileMenuContent.classList.remove('search-open');
            mobileMenuContent.classList.remove('c-topnav__list--open');
            mobileMenuSearchTrigger.classList.remove('c-topnav__mobile-search--open');
          }

          document.body.classList.toggle('mobile-menu-open');

          const menuIsOpen = document.body.classList.contains('mobile-menu-open');
          document.body.classList.toggle('modal-open', menuIsOpen);
          mobileMenuContent.classList.toggle('c-topnav__list--open', menuIsOpen);
          mobileMenuOpen.classList.toggle('c-topnav__mobile-trigger--open', menuIsOpen);
        }
      });
    });
  }

  function bindMobileSubmenuLogic() {
    const mainMenuItems = document.querySelectorAll('.c-topnav__main-item');
    const nestedSubmenus = document.querySelectorAll('.c-topnav__column');

    if (!mainMenuItems) {
      return;
    }

    // Main menu items
    mainMenuItems.forEach(menuItem => {
      const subMenu = menuItem.querySelector('.c-topnav__submenu');
      if (!subMenu) {
        return;
      }

      menuItem.addEventListener('click', (e) => {
        // Up 2 levels to check if we have the main menu item
        if (e.target !== menuItem && e.target.parentNode !== menuItem && e.target.parentNode.parentNode !== menuItem) {
          return;
        }

        if (isMobileSize()) {
          e.preventDefault();

          if (menuItem.classList.contains('is-open')) {
            menuItem.classList.remove('is-open');
            mainMenuItems.forEach(menuItem => menuItem.classList.remove('c-topnav__main-item--hidden'));
          } else {
            menuItem.classList.add('is-open');

            const menuItemsToHide = document.querySelectorAll('.c-topnav__main-item:not(.is-open):not(.search)');
            menuItemsToHide.forEach(menuItem => menuItem.classList.add('c-topnav__main-item--hidden'));
          }
        } else {
          // if search menu item on non-mobile, prevent redirect
          if(menuItem.classList.contains('search')) {
            e.preventDefault();
          }
        }
      })
    })

    // Nested sub-menus
    if (nestedSubmenus) {
      nestedSubmenus.forEach(nestedSubmenu => {
        const nestedSubMenuTrigger = nestedSubmenu.querySelector('.c-topnav__column.item'),
          nestedSubMenuList = nestedSubmenu.querySelector('.c-topnav__columns-items');

        if (nestedSubMenuTrigger) {
          nestedSubMenuTrigger.addEventListener('click', () => {
            if (isMobileSize()) {
              nestedSubMenuList.classList.toggle('c-topnav__columns-items--open');
              nestedSubMenuTrigger.classList.toggle('is-open');
            }
          })
        }
      })
    }
  }

  function initSubmenuVisual() {
    let initialVisual = '';
    const initialVisualElement = document.querySelector('[data-initialvisual]'),
      submenuVisualTriggers = document.querySelectorAll('[data-hovervisual]'),
      videoContainer = document.querySelector('.c-topnav__submenu-videoContainer');

    // Initial visual
    if (initialVisualElement) {
      initialVisual = `url("${initialVisualElement.dataset.hovervisual}")`;
      submenuVisual.style.backgroundImage = initialVisual;
    }

    submenuVisualTriggers.forEach(visualTrigger => {

      visualTrigger.addEventListener('mouseenter', () => {
        const hoverVisualString = `url("${visualTrigger.dataset.hovervisual}")`;

        if (hoverVisualString !== submenuVisual.style.backgroundImage) {
          submenuVisual.style.backgroundImage = hoverVisualString;
        }
        if(visualTrigger.dataset.hovervideo && window.player['#c-topnav__submenu-videoPlayer']) {
          videoContainer.classList.remove('c-topnav__submenu-videoContainer--hide');
          window.player['#c-topnav__submenu-videoPlayer'].source = {
            type: 'video',
            sources: [
              {
                src: visualTrigger.dataset.hovervideo,
                type: 'video/mp4',
              }
            ],
            poster: visualTrigger.dataset.hovervisual,
          };
          window.player['#c-topnav__submenu-videoPlayer'].play();
        }else {
          videoContainer.classList.add('c-topnav__submenu-videoContainer--hide');
        }
      });

      visualTrigger.addEventListener('mouseleave', () => {
        if (submenuVisual.style.backgroundImage !== initialVisual) {
          submenuVisual.style.backgroundImage = initialVisual;
        }
      });
    });

  }

  function initSubmenuTitle() {
    let initialTitle = '';
    const submenuTitleTriggers = document.querySelectorAll('[data-hovertitle]'),
      initialTitleElement = document.querySelector('[data-initialtitle]');

    // Initial title
    if (initialTitleElement) {
      initialTitle = initialTitleElement.dataset.hovertitle;
      submenuVisualTitle.innerHTML = initialTitle;
    }

    submenuTitleTriggers.forEach(titleTrigger => {
      titleTrigger.addEventListener('mouseenter', () => {
        const hoverVisualTitle = titleTrigger.dataset.hovertitle;

        if (hoverVisualTitle !== submenuVisualTitle.innerHTML) {
          submenuVisualTitle.innerHTML = hoverVisualTitle;
        }
      });

      titleTrigger.addEventListener('mouseleave', () => {
        if (submenuVisualTitle.innerHTML !== initialTitle) {
          submenuVisualTitle.innerHTML = initialTitle;
        }
      });
    });
  }
});

