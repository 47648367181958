define('Kalkhoff_ESSearch/js/essearch',[
    'Vue',
    'vue!ESSearchComponents/LoadingIndicator.vue',
    'vue!KalkhoffESSearchComponents/ESSearch.vue',
    'vue!KalkhoffESSearchComponents/SearchInput.vue',
    'vue!KalkhoffESSearchComponents/ResultsGroup.vue',
    'vue!KalkhoffESSearchComponents/Result.vue'
], function (Vue) {
    'use strict';

    const mageJsComponent = (config) => {
        const appContainer = document.querySelector(config.element);

        if (!appContainer) {
            return;
        }


        new Vue({
            el: config.element,

            data: {
                search: {
                    query: '',
                    results: false,
                    loading: false,
                    triggerLimit: config.triggerLimit,
                    redirectUrl: config.redirectUrl,
                },
                translations: config.translations,
                showLoadMore: config.showLoadMore,
                showCategoryVisual: config.showCategoryVisual,
                resultLimitWithCategory: config.resultLimitWithCategory,
                resultLimitWithoutCategory: config.resultLimitWithoutCategory,
                scrollTopOnSearch: config.scrollTopOnSearch
            }
        });
    };

    const bindMenuTrigger = () => {
        const searchMenuItem = document.querySelector('.c-topnav__main-item.search'),
            searchTrigger = searchMenuItem.querySelector('a.search'),
            searchTriggerMobile = document.querySelector('.c-topnav__mobile-search'),
            searchSubmenu = searchMenuItem.querySelector('.c-topnav__submenu'),
            menuOpenClass = 'is-open';

        document.body.addEventListener('click', (e) => {
            if (e.target !== searchTrigger && !searchMenuItem.contains(e.target)) {
                return searchSubmenu.classList.remove(menuOpenClass);
            }
        })

        searchTrigger.addEventListener('click', (e) => {
            if (e.target !== searchTrigger) {
                return;
            }

            searchSubmenu.classList.toggle(menuOpenClass);

            if (searchSubmenu.classList.contains(menuOpenClass)) {
                searchSubmenu.querySelector('input').focus();
            }
        });
    }

    bindMenuTrigger();

    return mageJsComponent;
});

