define('Ponbike_Bidex/js/bidex',['jquery'], function($) {
    var mageJsComponent = function(config) {

        $(document).ready(function() {
            var $button = $(config.button);
            var $modal = $(config.modal);
            var $close = $(config.close);
            var $background = $(config.background);
            var $iframe = $(config.iframe);
            var bidexBaseUrl = 'https://2.local.bidex.bike/Shops/'
            var showClass = 'bidex__modal--show';
            var loadingClass = 'bidex__modal--loading';
            var countryFilter = config.shouldSetCountryFilter ? `&SelectedCountry=${config.countryCode}&Country=${config.countryCode}` : '';

            $button.click(function(ev) {
                ev.preventDefault();

                $iframe.attr('src', `${bidexBaseUrl}?bchID=${$(this).data('style-id')}&gtin=${$(this).data('article-nr')}&showFilter=true${countryFilter}`);

                $modal.addClass(loadingClass);
                $modal.toggleClass(showClass);
                $background.toggleClass(showClass);

                $iframe.on('load', function () {
                    $modal.removeClass(loadingClass);
                })
            });

            $close.click(function(ev){
                $modal.toggleClass(showClass);
                $background.toggleClass(showClass);
            });

            $background.click(function(ev){
                $modal.toggleClass(showClass);
                $background.toggleClass(showClass);
            });
        });

    }
    return mageJsComponent;
});

