/**
 * Initiate a collapsible element. If a custom translation
 * is necessary, include a translations object as second param.
 * If no data-target is given, the next element in the DOM is targeted.
 *
 * Usage:
 * collapse(trigger, translate, translations)
 *
 * @author Martijn Rijnja <martijn.r@me.com>
 */

define('js/collapse',[
    'jquery',
    'mage/translate'
], function ($, $t) {
    'use strict';

    var lessClass = 'less';
    var translations = {
        'more_info': $t('More information'),
        'less_info': $t('Less information')
    };

    return function (el, translate, customTranslations) {
        var $document = $(document);

        $document.on('click', el, function () {
            var $element = $(this);
            var $target = $($element.data('target'));

            if (!$target.length) {
                $target = $element.next();
            }

            if (!_.isUndefined($target)) {
                $target.slideToggle();

                if ($target.height() > 1) {
                    if (translate) $element.html(translations.more_info);
                    $element.removeClass(lessClass);
                } else {
                    if (translate) $element.html(translations.less_info);
                    $element.addClass(lessClass);
                }
            }
        });
    }
});

