define('Kalkhoff_Translation/js/mage-translation-dictionary',[
    'text!js-translation.json'
], function (dict) {
    'use strict';

    if (typeof dict === 'string' && dict.length > 0) {
        return JSON.parse(dict);
    }

    return {};
});

