define('Ponbike_BrowserDetect/js/popup',[
    'jquery',
    'detect',
    'mage/cookies'
], function ($, detect) {
    'use strict';

    var content = $(".browserSupport__content"),
        background = $(".browserSupport__background"),
        container = $('.browserSupport'),
        showClass = 'browserSupport__show';

    var popup = function(url, type) {
        $.ajax({
            url: url  + 'rest/V1/browserdetect/' + type ,
            type: 'GET',
            complete: function(response,  $) {
                if (response.status == 200) {
                    content.html(response.responseJSON);
                    background.addClass(showClass);
                    container.addClass(showClass);
                }
            }
        });
    }

    var browserDetectPopUp = function(config) {
        if ($.cookie("browserdetect") !== null) { //Only show warning / recheck versions every 7 days
            return;
        }
        // Disable browser detect cookie, since we want to always show that there is something
        // and force reset of cookie for previous visitors.
        $.cookie("browserdetect", 1, {path: '/', expires: -1});

        var ua = detect.parse(navigator.userAgent);

        var family = ua.browser.family;
        var major = ua.browser.major;

        var unsupported = false;
        for(var i=0; i<config.unsupported.length; i++) {
            if(config.unsupported[i] === family) {
                unsupported = true;
            }
        }

        var matchedBrowser = null;
        for(var sup in config.supported){
            if(sup === family) {
                matchedBrowser = sup;
                break;
            }
        }

        if (unsupported) {
            popup(config.url, "unsupported");
        } else if (config.hasWarning && !matchedBrowser) {
            popup(config.url, "notsure");
        } else {
            var version = config.supported[matchedBrowser];
            // Check version and be sure that major version is filled else there is use of an in-app or unknown likely modern browser.
            if (version > major && major !== null) {
                popup(config.url, "outdated");
            } else {
                // Browser is supported, remove elements
                container.remove();
                background.remove();
            }
        }

        $('.browserSupport__close').click(function(ev){
            ev.preventDefault();

            background.toggleClass(showClass);
            container.toggleClass(showClass);
        })
    };

    return browserDetectPopUp;

});

