define('Kalkhoff_Header/js/topbar',['jquery'], function ($) {
    var mageJsComponent = function () {
        $(document).ready(function () {
            if ($('#usp_topbar') && $('#topnav')) {
                setCorrectSearchDropdownPosition();
                initResizeEvent();
            }
        });

        function setCorrectSearchDropdownPosition() {
            const newTop = $('#topnav').outerHeight() + $('#usp_topbar').outerHeight();
            $('#topnav .search .c-topnav__submenu').css('top', `${newTop}px`);
        }

        function initResizeEvent() {
            var resizeTimer;

            $(window).on('resize', function (e) {
                clearTimeout(resizeTimer);

                resizeTimer = setTimeout(function () {
                    setCorrectSearchDropdownPosition();
                }, 250);

            });
        }
    };

    return mageJsComponent;
});


