define('Kalkhoff_BlueFootEnhancements/js/services-accordion',[
    'jquery',
    'accordion',
    'matchMedia'
], function ($, accordion, mediaCheck) {
    mediaCheck({
        media: '(min-width: 768px)',

        entry: function () {
            try {
                $('.s--services').accordion('destroy');
            } catch(e) {}

            $('.s--services [data-role]').show();
        },

        exit: function () {
            $('.s--services').accordion();
        }
    });
})
;
