/**
 * Small jquery ui tooltip
 *
 * @author Martijn Rijnja <martijn.r@me.com>
 */

define('js/info-tooltip',[
    'jquery',
    'jquery-ui-modules/tooltip'
], function($) {
    'use strict';

    return function(el, viewport, trigger) {

        $(el).tooltip({
            html: true,
            trigger: trigger || 'focus',
            viewport: viewport,
            container: viewport,
            placement: 'auto top'
        });
    };
});

