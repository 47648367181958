define('Kalkhoff_BlueFootEnhancements/js/foldable',['jquery'], function ($) {
    const WRAPPER_SELECTOR = '.foldable__wrapper';
    const TRIGGER_SELECTOR = '.foldable__trigger';
    const EXPANDED_CLASS = 'expanded';
    const OPEN_CLASS = 'open';

    class Foldable {
        constructor(config, element) {
            this.element = element;
            this.wrapper = element.querySelector(WRAPPER_SELECTOR);
            this.trigger = element.querySelector(TRIGGER_SELECTOR);

            this.bindFoldableTrigger = this.bindFoldableTrigger.bind(this);
            this.openFoldableList = this.openFoldableList.bind(this);
            this.bindItemExpand = this.bindItemExpand.bind(this);

            if (this.trigger) {
                this.bindFoldableTrigger();
            }

            if (config.hasOwnProperty('itemClass')) {
                this.clickableItemClass = `.${config.itemClass}-trigger`;
                this.expandItemsSelector = `.${config.itemClass}`;
                this.bindItemExpand();
            }
        }

        bindFoldableTrigger() {
            const self = this;

            $(self.element).on('click', TRIGGER_SELECTOR, function (event) {
                self.closeOpenItems();
                self.wrapper.classList.toggle(OPEN_CLASS);
            });
        }

        openFoldableList() {
            const self = this;

            self.closeOpenItems();
            self.wrapper.classList.toggle(OPEN_CLASS);
        }

        closeOpenItems() {
            $(this.expandItemsSelector).removeClass(EXPANDED_CLASS);
        }

        bindItemExpand() {
            const self = this;

            $(self.element).on('click', self.clickableItemClass, function () {
                const $expandItem = $(this).closest(self.expandItemsSelector),
                    isExpanded = $expandItem.hasClass(EXPANDED_CLASS);

                if (!self.wrapper.classList.contains(OPEN_CLASS)) {
                    self.openFoldableList();
                }

                self.closeOpenItems();
                if (isExpanded) {
                    return;
                }

                setTimeout(() => $expandItem.addClass(EXPANDED_CLASS), 50);
            });
        }
    }

    return function (config, element) {
        const foldable = new Foldable(config, element);
    }
});

