
(function(template){
    define("vue!ESSearchComponents/LoadingIndicator.vue", ['Vue'], function (Vue) {
        Vue.component('loading-indicator', {
            template: template,

            props: ['loading']
        });
    });
})('' + 
' <div class="essearch__loading" :class="{\'essearch__loading--active\': loading}">' + 
' <div class="essearch__loader">' + 
' <span></span>' + 
' <span></span>' + 
' <span></span>' + 
' <span></span>' + 
' </div>' + 
' </div>' + 
'' + '');
