/*jshint browser:true jquery:true*/
/*global confirm:true*/
define(
    'Pon_AJAXCatalog/js/plugins/jquery.is-in-viewport',[
        'jquery'
    ],
    function ($, ui, _, Component, mageTemplate, priceUtils) {
        "use strict";

        $.fn.isInViewport = function() {
            var $this = $(this),
                $window = $(window),
                elementTop = $this.offset().top,
                elementBottom = elementTop + $this.outerHeight(),
                viewportTop = $window.scrollTop(),
                viewportBottom = viewportTop + $window.height();

            return elementBottom > viewportTop && elementTop < viewportBottom;
        };
    }
);

