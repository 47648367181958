/*jshint browser:true jquery:true*/
/*global confirm:true*/
define(
    'Kalkhoff_AjaxPop/js/navigation-enhancement',[
        'jquery',
        'jquery/ui-modules/widgets/slider',
        'underscore',
        'mage/translate',
        'Pon_AJAXCatalog/js/navigation',
        'mage/template',
        'mage/apply/main',
        'infoTooltip',
        'lazyImage',
        'collapse',
        'mage/validation',
        'Magento_Swatches/js/swatch-renderer',
        'uiTouchPunch'
    ],
    function (
        $,
        ui,
        _,
        $t,
        ajaxNavigation,
        mageTemplate,
        mage,
        infoTooltip,
        LazyImage,
        collapse
    ) {
        "use strict";

        var self,
            $document = $(document),
            $window = $(window),
            $body = $('body'),
            ajaxBusy;

        var filterClicks = 0;
        var oldReferrerHash = '';
        var oldReferrerHashBackup = '';
        var newReferrerHash = '';
        var filterTrigger = false;
        var redirectTriggered = false;
        var resetTrigger = false;
        var broadcastImpression = true;

        return ajaxNavigation.extend({

            defaults: {
                allProductsExceptServiceTile: '.products .product-item:not(.service-tile)',
                bannerSelector: '.banner-card',
                filterContainers: '.js-filters.filter-options',
                hiddenOnDesktopClasses: 'not-shown',
                filterLimit: 7,
                allFiltersShown: true,
                navigationItemSelector: '.filter-options__content .filter-options__item, .filter-tiles__content .filter-tiles__option',
                loadMoreSelector: '.ajax-load-more',
                loadMoreTmpSelector: '#pon-ajaxcatalog-load-more-',
                showMoreFiltersSelector: '.show-more-filters-container',
                showMoreFiltersButtonTemplate: '#pon-ajaxcatalog-filter-show-more-filters-',
                zeroResultsTemplateSelector: '#pon-ajaxcatalog-zero-results-',
                noResultsSelector: '.no-results',
                toolbar: '.toolbar',
                toolbarAmountSelector: '.toolbar-amount',
                sliderSelectors: '.js-filters .option-slider',
                filterElementSelector: '#pon-toolbar',
                hiddenClass: 'hidden',
                navigationArea: '.js-filters',
                toolbarSelector: '.product-count',
                navigationButtonTmplSelector: '#pon-ajaxcatalog-navigation-button-',
                navigationItemClearSelector: '.bike-filter__form-clear',
                navigationItemClearBottomContainer: '.bike-filter__form-clear--bottom',
                navigationItemEmptySelector: '.filter-options__clear-current',
                navigationItemCloseButton: '.bike-filter__apply',
                batterySliderSelector: '#pim_itembatterywatthours-slider',
                priceSliderSelector: '#price-slider',
                moreBtnSelector: '.filter-options__more-button',
                slideFilterSelector: '.filter-options.option-slider',
                swatchOptionSelector: '.swatch-option',
                toggleFilterBtnSelector: '.toggle-filter',
                defaultPageTitle: document.title,
                searchQueryTitleSelector: 'search-title',
                categoryUrl: 'bikes',
                productItemClass: 'product-item',
                featuredNavigationArea: '#featuredFilters',
                featuredNavigationAreaMobile: '#featuredFiltersMobile',
                featuredLayeredNav: {
                    attributes: ['pim_groupusage'],
                }
            },
            productCategoryMapping: [],
            colorSwatchMapping: [],
            toolbarElement: $('.toolbar'),

            initialize: function () {
                $(document).on('ajaxlist:filters:shown', function () {
                    self.allFiltersShown = true;
                    self.$showMoreFilters.hide();
                });

                this._super();
            },

            initProperties: function () {
                this._super();
                self = this;
                self.$navigationButton = $(self.navigationItemCloseButton);
                self.$showMoreFilters = $(self.showMoreFiltersSelector);

                // Load mobile filter in navigation list instead of above products
                // due to design. Least hacky solution.
                if (window.innerWidth < 750) {
                    self.$featuredFilterList = $(self.featuredNavigationAreaMobile);
                } else {
                    self.$featuredFilterList = $(self.featuredNavigationArea);
                }
            },

            rebuild: function (data) {
                this._super();
                self = this;

                ajaxBusy = 0;

                self.initClearFilter(data.navigation);
                self.initLoadMore({ loadMoreText: data.loadMoreText, left: data.left });
                self.initNavigationButton(data.toolbar);
                self.initRememberScrollPosition();
                self.dispatchEvent('ajaxlist:rebuild:after', data);
                self.setCategoryData(data.category);

                $('.slider-histogram-item').each(function(){
                    $(this).css({
                        width: 'calc(' + $(this).attr('data-width') + '% - 1px)', // Removing 1px for the border
                        height: $(this).attr('data-height') + '%',
                    });
                });
            },

            /**
             * @param categoryData
             */
            setCategoryData: function (categoryData) {
                if (typeof categoryData === 'undefined') {
                    return;
                }

                const attributes = {
                    'name': '.category-header h1',
                    'description': '.category-header__description',
                    'image': '.category-header--image',
                }

                Object.keys(attributes).forEach((key) => {
                    if (categoryData.hasOwnProperty(key)) {
                        const attributeClass = attributes[key];
                        const attributeValue = categoryData[key];

                        if (!attributeValue || attributeValue === null) {
                            return;
                        }

                        if (key === 'image') {
                            $(attributeClass).css({
                                'background': 'url('+ attributeValue +') no-repeat center',
                                'background-size': 'cover'
                            });

                            return;
                        }

                        $(attributeClass).html(attributeValue);
                    }
                });
            },

            initLazyLoader: function () {
                return true; // Prevent loading on scroll
            },

            initLoadMore: function (data) {
                self.$loadMore = $(self.loadMoreSelector);

                if (self.$loadMore.length) {
                    var template = self.getTemplate(self.loadMoreTmpSelector, { data: data });

                    self.setInnerHtml(self.$loadMore[0], template);
                }
            },

            initShowMoreFilters: function () {
                if (self.$showMoreFilters.length
                    && self.filterLimit !== false
                    && self.filterLimit < $(self.filterContainers).children().length) {
                    self.$showMoreFilters.html(self.getShowMoreFiltersTemplate());
                }
            },

            /**
             * @param data
             */
            initNavigationButton: function (data) {
                self = this;
                if (self.$navigationButton.length) {
                    self.setInnerHtml(self.$navigationButton[0], self.getNavigationButtonTemplate({data: data}));
                }
            },

            initRememberScrollPosition: function() {
                document.querySelectorAll('.product-item').forEach(product => product.addEventListener("click", () => localStorage.setItem("pop-scroll", $(window).scrollTop())));
            },

            GTMDataLayer: function(data) {
                let items = [];
                let index = 0;
                _.each(data.products, function (product) {
                    let vueProduct = JSON.parse(product.vue);
                    let productWithPrice = vueProduct.simples.find(simple => simple && simple.price && simple.price.cleanCurrentPrice);
                    if (productWithPrice !== undefined && productWithPrice.length) {
                        items.push(
                            {
                                item_id: vueProduct.defaultProduct.sku,
                                item_name: product.name,
                                index: index++,
                                price: productWithPrice.price.cleanCurrentPrice ? productWithPrice.price.cleanCurrentPrice : 0
                            }
                        );
                    }
                });

                var formatedObj = {
                    event: 'view_item_list',
                    ecommerce: {
                        item_list_id: 'category',
                        item_list_name: 'category',
                        items: items
                    }
                };

                window.dataLayer.push(formatedObj);
            },

            initEventHandlers: function () {
                self = this;

                $document.on('ajaxlist:initialized', function () {
                    self.initCollapsibleFilters();
                    self.initTooltips();
                    self.initFixedToolbar();
                });

                $document.on('ajaxlist:initList:after', function (event, data) {
                    self.afterInitializeList(data);
                    self.preselectSwatches();
                    self.orderList();
                    self.positionBanners();
                    self.GTMDataLayer(data);
                });

                $document.on('ajaxlist:initProduct:before', function (event, product) {
                    var productData = product.product;
                    self.initColorMapping(productData.color_mapping);
                });

                $document.on('ajaxlist:reset', function (event) {
                    self.resetToBaseLocation();
                    filterTrigger = true;
                    resetTrigger = true;
                });

                $document.on('ajaxlist:queried', function (event) {
                    self.handlePreviousScrollPosition();
                });

                /**
                 * Range sliders
                 */
                $document.on('slide', self.sliderSelectors, function (event, ui) {
                    if (ui.values) {
                        $.each(ui.values, function (key, value) {
                            if ($(`#${event.currentTarget.id}-selector-${key}`).length > 0) {
                                $(`#${event.currentTarget.id}-selector-${key}`).val(value);
                            }
                        });
                    }
                });

                $document.on('slidechange', self.slideFilterSelector, function (event, ui) {
                    if (_.isEmpty(event.currentTarget.id)) {
                        return;
                    }

                    var elementId = event.currentTarget.id;
                    var action = $('#'.elementId).closest('[data-attribute]').data('attribute');
                    var values = ui.values;
                    var label;

                    if (0 in values === false || 1 in values === false) {
                        return;
                    }

                    action = (_.isUndefined(action)) ? elementId : action;
                    label = values[0] + ',' + values[1];

                    filterTrigger = true;
                });

                /**
                 * Hash changes
                 */
                $(window).on('hashchange', function () {
                    if (self.detectNotBackButton()) {
                        if (!redirectTriggered) {
                            self.doRequest();
                        }
                    }
                });

                self.initClickHandlers();

                this._super();
            },

            handlePreviousScrollPosition: function() {
                let top = localStorage.getItem("pop-scroll");
                if (top !== null) {
                    setTimeout(() => $window.scrollTop(parseInt(top, 10)), 350, { duration: 350 });
                }
                localStorage.removeItem("pop-scroll");
            },

            toggleFilterItem: function (attr, value) {
                var filter = {},
                    params = self.getUrlParams();

                value = value.toString();

                if (_.has(params, attr)) {
                    if (_.contains(params[attr], value)) {
                        filter[attr] = _.filter(params[attr], function (paramValue) {
                            return paramValue !== value; });
                        $.bbq.pushState(filter);
                        self.resetToBaseLocation(false);

                        return;
                    } else {
                        filter[attr] = params[attr].concat([value]);
                    }
                } else {
                    filter[attr] = [value];
                }

                $.bbq.pushState(filter);
            },

            getSerializedUrl: function () {
                var str = [],
                    obj = $.deparam.fragment();

                _.each(obj, function (value, index) {
                    if (_.isEmpty(value) || _.isEmpty(index)) {
                        return;
                    }

                    if (index !== 'page' && index !== 'price') {
                        index = index + "[]";
                    }

                    if (_.isArray(value)) {
                        _.each(value, function (valueItem) {
                            str.push(encodeURIComponent(index) + "=" + encodeURIComponent(valueItem));
                        });

                        return;
                    }

                    str.push(encodeURIComponent(index) + "=" + encodeURIComponent(value));
                });

                return str.join("&");
            },

            /**
             * Detect if back button is triggered, then return to the page before the POP
             *
             * @returns {boolean}
             */
            detectNotBackButton: function () {
                newReferrerHash = self.stripHashCode(window.location.hash);
                if (newReferrerHash === '') {
                    newReferrerHash = self.stripHashCode('#page=1');
                }

                if (newReferrerHash === oldReferrerHashBackup) {
                    if (!filterTrigger) {
                        history.go(filterClicks);
                        redirectTriggered = true;
                        return false;
                    }

                    if (resetTrigger) {
                        oldReferrerHash = oldReferrerHashBackup;
                    }
                }

                oldReferrerHashBackup = oldReferrerHash;
                oldReferrerHash = newReferrerHash;
                filterTrigger = false;
                redirectTriggered = false;
                resetTrigger = false;
                --filterClicks;

                return true;
            },

            getLayerNavigationHtml: function (layers) {
                if (!_.isUndefined(layers)) {
                    var count = 1;

                    if (self.filterLimit !== false && !self.allFiltersShown) {
                        layers.map(function (layer) {
                            if (!_.isUndefined(layer.attribute) && !_.isUndefined(layer.items)) {
                                count++;
                                layer.isActive = layer.items.some((filter) => filter['active'] === true);
                                layer.isShowMoreFilter = count > self.filterLimit && !layer.isActive;

                                return layer;
                            }
                        });
                    }
                }

                return this._super(layers);
            },

            initClickHandlers: function () {
                /**
                 * Sorting
                 */
                $('select#sorter').change(function () {
                    var value = this.value;

                    self.setSortOrder(value);
                });

                /**
                 * More btn
                 */
                $document.on('click', '#btn-pop-load-more', function (e) {
                    filterTrigger = true;
                    broadcastImpression = true;

                    self.lazyLoadNextPage();
                });

                /**
                 * Show more filters button
                 */
                $document.on('click', self.showMoreFiltersSelector, function () {
                    $(self.filterContainers).children().removeClass(self.hiddenOnDesktopClasses);
                    self.allFiltersShown = true;
                    $document.trigger('ajaxlist:filters:shown');
                    $(this).remove();
                });

                $document.on('click', self.moreBtnSelector, function (e) {
                    e.preventDefault();
                    self.toggleAllFilters(e);
                });

                /**
                 * Click on filter item
                 */
                $document.on('click change', self.navigationItemSelector, function (e) {
                    var $element = $(this);
                    var action = $element.closest('[data-attribute]').data('attribute');
                    var label = $element.closest('[data-value]').data('value');

                    filterTrigger = true;

                    if (_.isUndefined(action) || _.isUndefined(label)) {
                        return;
                    }
                });

                /**
                 * Empty
                 */
                $document.on('click', self.navigationItemEmptySelector, function (e) {
                    var $closestDataEl = $(e.target).closest('[data-attribute]');
                    var data = {
                        attribute: $closestDataEl.data('attribute')
                    };
                    filterTrigger = true;

                    var $checkedItems = $closestDataEl.find('.item:checked');
                    if ($checkedItems.length === 1) {
                        var $firstItem = $($checkedItems[0]);
                        var itemId = $firstItem.attr('id');
                        $('label.item[for="' + itemId + '"]').click();

                        return false;
                    }

                    $document.trigger('ajaxlist:clear', data);
                });

                /**
                 * Clear btn all filters
                 */
                $document.on('click', self.navigationItemClearSelector, function () {
                    filterTrigger = true;
                });

                /**
                 * Clear btn specific filter
                 */
                $document.on('click', self.navigationItemEmptySelector, function () {
                    var $element = $(this);
                    var label = $element.closest('[data-attribute]').data('attribute');

                    filterTrigger = true;
                });

                /**
                 * Mobile filter open/close
                 */
                $document.on('click', self.toggleFilterBtnSelector, function () {
                    var $currentItem = $(this);
                    var label = 'open';

                    if ($currentItem.hasClass('bike-filter__apply')) {
                        label = 'apply button'
                    } else if ($currentItem.hasClass('bike-filter__close')) {
                        label = 'close';
                    }
                });

                /**
                 * Swatch
                 */
                $document.on('click', self.swatchOptionSelector, function (e) {
                    if (!_.isUndefined(e.isTrigger)) {
                        return;
                    }

                    var $item = $(this);
                    var $parent = $item.closest('.product-item-details');
                    var label = $parent.find('.product-item-name a').text();
                    var type = ($item.hasClass('color')) ? 'color' : 'model';
                });

            },

            /**
             * @param {object} data
             */
            afterInitializeList: function (data) {
                self = this;

                self.initSwatches(data.products);

                if (mage) {
                    mage.apply();
                }
            },

            /**
             * Set toolbar fixed on mobile
             */
            initFixedToolbar: function () {
                var navbar = $('.navbar-fixed-top');
                var openClass = 'open';
                var toolbarClone = self.toolbarElement.clone(true).addClass('fixed container');

                window.toolbarTop = self.toolbarElement.offset().top;

                if (!window.stickyToolbar) {
                    navbar.append(toolbarClone);
                    window.stickyToolbar = $('.toolbar.fixed');

                    $window.scroll(function () {
                        if ($window.scrollTop() >= window.toolbarTop) {
                            window.stickyToolbar.addClass(openClass);
                        } else {
                            window.stickyToolbar.removeClass(openClass);
                        }
                    });
                } else {
                    window.stickyToolbar.replaceWith(toolbarClone);
                    window.stickyToolbar = $('.toolbar.fixed');
                }
            },

            getShowMoreFiltersTemplate: function () {
                return self.getTemplate(self.showMoreFiltersButtonTemplate);
            },

            /**
             * Returns navigation button template
             * @param data
             * @returns {*}
             */
            getNavigationButtonTemplate: function (data) {
                return self.getTemplate(self.navigationButtonTmplSelector, data);
            },

            /**
             * Initialize the swatch data received from elastic search
             *
             * @param products
             */
            initSwatches: function (products) {
                _.each(products, function (product) {
                    if (_.has(product, 'swatches_js')) {
                        var $swatch = $('.swatch-opt-' + product['swatches_js']['productId']);
                        if (!$swatch.is(":data('pon-SwatchRenderer')")) {
                            $swatch.SwatchRenderer(product['swatches_js']);
                        }
                    }
                });
            },

            initTooltips: function () {
                var $tooltipIcons = $('.js-filters .icon-info');
                infoTooltip($tooltipIcons, $(self.navigationArea));

                $tooltipIcons.on('click', function (event) {
                    event.preventDefault();
                });
            },

            initFilters: function () {
                var self = this;

                $(self.navigationItemSelector).on('click change', self.handleFilterItem.bind(this));

                $(self.priceSliderSelector).each(function (idx, element) {
                    const config = JSON.parse($(element).attr('data-config'));
                    self.initSliderSelectors(element, config);
                });

                $(self.batterySliderSelector).each(function (idx, element) {
                    const config = JSON.parse($(element).attr('data-config'));
                    self.initSliderSelectors(element, config);
                });
            },

            /**
             * @param element
             * @param config
             */
            initSliderSelectors: function (element, config) {
                const self = this,
                    originalValues = config.originalValues,
                    configCode = config.code,
                    $priceSlideSelectorLeft = $('#'+ configCode +'-price-slider-selector-0'),
                    $priceSlideSelectorRight = $('#'+ configCode +'-price-slider-selector-1');

                config.slide = function(event, ui) {
                    $priceSlideSelectorLeft.val(originalValues[ui.values[0]]);
                    $priceSlideSelectorRight.val(originalValues[ui.values[1]]);
                }

                $(element).slider(config)
                    .on('slidechange', function (event, ui) {
                        const priceSlideValLeft = originalValues[ui.values[0]],
                            priceSlideValRight = originalValues[ui.values[1]];

                        self.setPage(1);

                        $priceSlideSelectorLeft.val(priceSlideValLeft);
                        $priceSlideSelectorRight.val(priceSlideValRight);

                        if (configCode === 'pim_itembatterywatthours') {
                            var selectedRange = [];
                            for (var i = ui.values[0]; i <= ui.values[1]; i++) {
                                selectedRange.push(originalValues[i] + ' ' + config.symbol);
                            }

                            $.bbq.pushState({pim_itembatterywatthours: selectedRange})
                        }

                        if (configCode === 'price') {
                            $.bbq.pushState({price: priceSlideValLeft + '-' + priceSlideValRight});
                        }

                        self.doRequest(false);
                    });

                const selectedMax = originalValues.hasOwnProperty(config.selectedMax) ? config.selectedMax : config.selectedMax - 1;
                $priceSlideSelectorLeft.val(originalValues[config.selectedMin]);
                $priceSlideSelectorRight.val(originalValues[selectedMax]);

                const originalValuesDivision = (100 / originalValues.length),
                    dividedItemWidth = (config.itemWidth / 2),
                    minWidthItemPosition = (config.selectedMin * originalValuesDivision),
                    minWidthItemPercentage = (minWidthItemPosition + dividedItemWidth),
                    maxWidthItemPosition = (config.selectedMax * originalValuesDivision) + dividedItemWidth,
                    maxWidthItemPercentage = (maxWidthItemPosition > 100 ? (100 - dividedItemWidth) : maxWidthItemPosition);

                $('#'+ configCode).find('.ui-slider-handle').css({'left': maxWidthItemPercentage + '%'}).attr('data-position', maxWidthItemPercentage);
                $('#'+ configCode).find('.ui-slider-handle:first').css({'left': minWidthItemPercentage + '%'}).attr('data-position', minWidthItemPercentage);
            },

            /**
             * Toggle long filter lists
             *
             * @param element
             */
            toggleAllFilters: function (element) {
                if (!_.isUndefined(element.isTrigger)) {
                    return;
                }

                var target = $(element.currentTarget);
                var parent = target.closest('.filter-options');
                var moreBtn = target.find('.more-button-text');
                var action = parent.closest('[data-attribute]').data('attribute');
                var label = 'show all';

                parent.toggleClass('item-hide');

                if (parent.hasClass('item-hide')) {
                    label = 'hide';
                    moreBtn.html('Hide');
                } else {
                    moreBtn.html('Show');
                }
            },

            /**
             * Set the product sort order
             * @param value
             */
            setSortOrder: function (value) {
                $.bbq.pushState({product_list_order: value});
                filterTrigger = true;
                self.doRequest(false, {fromPage: 0});
            },

            initCollapsibleFilters: function () {
                var $filters = $('.js-filters'),
                    $trigger = $('.js-filters-collapse'),
                    filterHeight = 46;

                if ($filters.length && $filters[0].scrollHeight > filterHeight) {
                    $trigger.show();
                }
            },

            /**
             * @param filters
             */
            initClearFilter: function (filters) {
                if (_.isUndefined(filters)) {
                    return;
                }

                var hidden = true;
                var $clearSelector = $(self.navigationItemClearSelector);
                var $closeSelector = $(self.navigationItemCloseButton);

                _.find(filters, function (filter) {
                    if (filter.items && _.some(filter.items, function (item) {
                        return item['active'] === true
                    })) {
                        hidden = false;
                    }

                    if (filter.template === 'slider') {
                        var min = filter.config.min,
                            max = filter.config.max,
                            values = filter.config.values;

                        if (min !== parseFloat(values[0]) || max !== parseFloat(values[1])) {
                            hidden = false;
                        }
                    }
                });

                if (hidden === false) {
                    $clearSelector.parent().addClass('show--clear-button');
                    $clearSelector.removeClass(self.hiddenClass);
                    $closeSelector.removeClass(self.hiddenClass);
                } else {
                    $clearSelector.parent().removeClass('show--clear-button');
                    $clearSelector.addClass(self.hiddenClass);
                    $closeSelector.addClass(self.hiddenClass);
                }
            },

            /**
             * @param {type} layer
             * @returns {undefined}
             */
            initLayeredNavigation: function (layer) {
                self.dispatchEvent('ajaxlist:initLayer:before', {layer: layer});

                layer = self.initFeaturedLayeredNavigation(layer);
                if (self.$filterList.length) {
                    self.setInnerHtml(self.$filterList[0], self.getLayerNavigationHtml(layer));
                }

                self.dispatchEvent('ajaxlist:initLayer:after', {filters: self.$filterList});
                self.initFilters();
            },

            initFeaturedLayeredNavigation: function (layer) {
                if (!_.isEmpty(self.featuredLayeredNav.attributes)) {
                    var featuredLayer = _.filter(layer, self.isFeaturedLayeredNavAttribute),
                        layer = _.filter(layer, self.isDefaultLayeredNavAttribute);

                    if (self.$featuredFilterList.length) {
                        self.setInnerHtml(self.$featuredFilterList[0], self.getLayerNavigationHtml(featuredLayer));
                    }
                }

                return layer;
            },

            isDefaultLayeredNavAttribute: function (attribute) {
                return !_.contains(self.featuredLayeredNav.attributes, attribute.attribute);
            },

            isFeaturedLayeredNavAttribute: function (attribute) {
                return _.contains(self.featuredLayeredNav.attributes, attribute.attribute);
            },
            /**
             * The color swatch mapping is generated by the indexer and added in elastic search.
             * The text filter is mapped with the tile color swatch.
             */
            preselectSwatches: function () {
                var colorSwatchMapping = this.colorSwatchMapping;
                $('.swatch').each(function () {
                    var $swatch = $(this);
                    if ($swatch.is(":data('pon-SwatchRenderer')")) {
                        var uiInstance = $swatch.data('pon-SwatchRenderer');
                        uiInstance.updateSelectedItemGenderFrame();
                        uiInstance.updateSelectedItemColor(colorSwatchMapping);
                    }
                });

                // If all swatches have been selected, lazy load all product images
                LazyImage.update.bind(LazyImage)();
            },

            /**
             * Get the latest part of the URI e.g. elektrische-fietsen
             * and strip out all query parameters.
             *
             * @returns {string}
             */
            getCurrentUriPathName: function () {
                var anchor = document.createElement('a');
                anchor.href = window.location.href;

                return anchor.pathname.replace(/^\//, '');
            },

            /**
             * Reset the page title to the default value
             *
             * @returns void
             */
            resetPageTitle: function () {
                document.title = self.defaultPageTitle;
            },

            initColorMapping: function (swatchColorMapping) {
                var self = this;
                $.each(swatchColorMapping, function (color, optionIds) {
                    if (self.colorSwatchMapping[color]) {
                        self.colorSwatchMapping[color] = _.union(self.colorSwatchMapping[color], optionIds);
                    } else {
                        self.colorSwatchMapping[color] = optionIds;
                    }
                });
            },

            /**
             * Adds CSS order to products
             */
            orderList: function () {
                $(self.allProductsExceptServiceTile).each(function (index, product) {
                    $(product).css('order', (index + 1) * 10);
                });
            },

            positionBanners: function () {
                $(self.bannerSelector).each(function (index, banner) {
                    $(banner).clone().addClass('cloned').appendTo('.product-items');
                });

                $(self.bannerSelector + ".cloned").each(function (index, banner) {
                    var position = $(banner).data('position') - 1;

                    $(banner).css({ 'display': 'flex' });
                    $(banner).get(0).style.setProperty("--responsiveGridPosition-M", position - 20);
                    $(banner).get(0).style.setProperty("--responsiveGridPosition-S", position - 30);
                });
            },

            /**
             * Get current currency
             *
             * @returns {string}
             */
            getCurrency: function () {
                return !_.isUndefined(window.dlCurrencyCode) ? window.dlCurrencyCode : 'EUR';
            },

            /**
             * Strip hash code
             *
             * @param str
             */
            stripHashCode: function (str) {
                return str.replace(/[_\s]/g, '').replace(/[^a-z0-9-\s]/gi, '').toLowerCase();
            },

            /**
             * Get last value of an array or return the string
             *
             * @param items
             * @returns {*}
             */
            getLastArrayValue: function (items) {
                return (_.isArray(items))
                    ? _.last(items)
                    : items;
            },

            /**
             * Get last value of an array by key
             *
             * @param items
             * @param keyToFind
             * @returns {*}
             */
            getLastArrayValueByKey: function (items, keyToFind) {
                if (!_.isArray(items) || typeof keyToFind === 'undefined') {
                    return self.getLastArrayValue(items);
                }

                var lastIndex = _.findLastIndex(items, keyToFind);
                if ((lastIndex === -1) || (typeof items[lastIndex] === 'undefined')) {
                    return '';
                }

                return items[lastIndex];
            },

            /**
             * @param {*} product
             * @returns string
             */
            getBikeType: function (product) {
                return _.has(product, 'pim_ebikeenginelocation') ? 'E-bike' : 'Non E-bike';
            },

            resetToBaseLocation: function (clear) {
                if (!self.ajaxProxyUrl) {
                    return;
                }

                clear = _.isUndefined(clear)
                    ? true
                    : clear;

                var request = (clear === false) ? "#" + self.getSerializedUrl() : "#page=1";

                window.history.pushState(null, null, self.baseLocation + request);
                self.ajaxProxyUrl = null;
            },

            setPage: function (page) {
                self.page = parseInt(page);

                $.bbq.pushState({ page: self.page });
            },

            lazyLoadNextPage: function () {
                if (!self.isEnd) {
                    $window.off('scroll.ajaxlist');
                    self.loadNextPage();
                }
            },

            loadNextPage: function () {
                self.insertDirection = 'append';
                var lastPage = self.getLastLoadedPage();
                self.page = lastPage + 1;
                self.fromPage = lastPage;
                self.loadPage(self.page, self.fromPage);
            },

            loadPage: function (page) {
                self.setPage(page);
                self.doRequest(true, { fromPage: self.fromPage });
            },

            doRequest: function () {
                if (ajaxBusy !== 1) {
                    ajaxBusy = 1;

                    var lazy = false;
                    var params = null;
                    // Original call with cache set to true
                    self.isLazyLoad = !_.isUndefined(lazy) && lazy === true;

                    if (window.$preloadCategoriesEnabled === true
                        && !self.isInitialized
                        && typeof window.$preloadCategories !== 'undefined'
                    ) {
                        window.$preloadCategories.then(function (preloadResponseData) {
                            self.rebuild(preloadResponseData);
                            self.afterRequest();
                        });

                        return;
                    }

                    params = params || {};

                    var data = _.extend(self.getUrlParams(), {
                        ajax: true,
                        page: self.page,
                        isLazy: self.isLazyLoad
                    }, params);

                    $.ajax({
                        url: self.url,
                        method: 'GET',
                        data: data,
                        cache: true, //CHANGED
                        showLoader: true,
                        success: function (response) {
                            self.rebuild(response);
                        }
                    }).done(function() {
                        self.afterRequest();
                    });
                    // End original call
                }
            },
        });
    }
);

